import React, {
    useContext, useState, useEffect
} from 'react';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { EmailQuoteService, LoadSaveService } from 'gw-capability-quoteandbind';
import { withRouter } from 'react-router-dom';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { publish } from '@jutro/events';
import { SessionTimeoutComponent } from 'gw-capability-sessiontimeout-react';
import ErrorHandlingUtil from '../../../../pages/Utils/ErrorHandlingUtil';
import styles from './PaymentConfirmationPage.module.scss';
import metadata from './PaymentConfirmationPage.metadata.json5';
import moment from 'moment';
import cookie from 'js-cookie';

function PaymentConfirmationPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const [docusignURL, setdocusignURL] = useState('');
    const [isLoading, updateIsLoading] = useState(false);
    const [showDocuSign, setShowDocuSign] = useState(false);
    const { submission } = props.location.state;
    const { confirmationNumber, pacIncluded } = submission;
    const { setDocusign, history } = props;
    const currentPage = "Payment Confirmation Page"; 

    let paymentAmount = '$--.--';

    const convertToAmount = (amount) => {
        if (amount) {
            const decimalPoint = amount.toString().split('.')[1];
            if (_.isEmpty(decimalPoint)) {
                return `$${amount}.00`;
            }
            if (!_.isEmpty(decimalPoint) && decimalPoint.length === 1) {
                return `$${amount}0`;
            }
        }
        return `$${amount}`;
    };

    const selectedPlan = submission.quoteData.offeredQuotes.filter((offeredQuote) => {
        return offeredQuote.publicID === submission.bindData.chosenQuote;
    })[0];
    let pacAmt = 0;
    if (pacIncluded === 'true') {
        pacAmt = 3;
    }

    const amount = pacAmt + selectedPlan.premium.paymentPlan.downPayment.amount + 33;
    const baseAmount = selectedPlan.premium.paymentPlan.downPayment.amount;
    paymentAmount = ['GA', 'MS'].includes(submission.baseData.accountHolder.primaryAddress.state)
        ? convertToAmount(baseAmount) : convertToAmount(amount);

    const publishEcommerce = () => {
        const id = submission.quoteID;
        const selectedPaymentPlan = submission.quoteData.offeredQuotes.filter((offeredQuote) => {
            return offeredQuote.publicID === submission.bindData.chosenQuote;
        })[0];
        const { total } = selectedPaymentPlan.premium;
        const offering = selectedPaymentPlan.branchName;
        const { taxes } = selectedPaymentPlan.premium;
        const productName = submission.baseData.productCode;
        const state = submission.baseData.accountHolder.primaryAddress.state;

        // EH-22717: Pass eCommerce code to dataLayer
        window.dataLayer.push({
            event: "Payment Confirmation Details",
            quoteId: id,
            name: productName,
            category: offering,
            revenue: total.amount,
            tax: taxes.amount,
            price: total.amount - taxes.amount,
            currency: total.currency,
            quoteState: state
        });
    };

    useEffect(() => {
        if (location.pathname.includes('payment-confirmation') && (history.action === 'POP' || submission.baseData.periodStatus === 'Bound')) {
            setDocusign(false);
            ErrorHandlingUtil.processErrorResponse(props, {errorDescription : 'Customer clicked browser back button during DocuSign', clickedBack: true}, submission, currentPage);
            return;
        }

        updateIsLoading(true);

        const date = _.get(submission, "baseData.sysDate");
        _.set(submission, "baseData.sysDate", moment(date).format("YYYY-MM-DD"));
        const bindPromise = LoadSaveService.bindSubmission(submission);

        bindPromise.then((res) => {
            //EH-23655: Set Final Agent Assignment only if the policy is Bound
            if (res.baseData.periodStatus.toLowerCase() === 'bound') {
                let assignedAgentId = "";
                let assignedAgentName = "";

                //Determine the agent assignment
                if (res.baseData.agentAssignment.toLowerCase() === "crcdefault") {
                    assignedAgentName = "crcdefault";
                }
                else {
                    assignedAgentId = res.baseData.agentInfo.agentInfo[0].producerCode.replace(/^0+/, '');
                    assignedAgentName = [res.baseData.agentInfo.agentInfo[0].firstName, res.baseData.agentInfo.agentInfo[0].lastName].join(" ");
                }
                
                //Push the information to DataLayer
                window.dataLayer.push({
                    event: "AgentInformation",
                    QuoteId: _.get(res, 'quoteID') || cookie.get('quoteID'),
                    AgentId: assignedAgentId,
                    AgentName: assignedAgentName
                });
            }

            publishEcommerce();
            sessionStorage.setItem('policy', res.bindData.policyNumber);
            _.set(res, "baseData.sysDate", moment(res.baseData.sysDate).format("YYYY-MM-DD"));
            const documentPromise = LoadSaveService.createDocuSignEnv(res.quoteID);
            documentPromise.then(() => {
                updateIsLoading(false);
            }, (error) => {
                console.log(error);
            });

            const responsePromise = EmailQuoteService.sendQuoteBindEmail(res);
            responsePromise.then(() => {
            }, () => {
            });
        }, (error) => {
            if (error.isTimeout === true || _.isUndefined(error.baseError)) {
                publishEcommerce();
            }
            updateIsLoading(false);
            ErrorHandlingUtil.processErrorResponse(props, error, submission, currentPage);

        });
    }, []);

    const signDocuments = () => {
        const eSigPromise = LoadSaveService.retrieveDocusignURL(submission.quoteID);
        eSigPromise.then((url) => {
            if (url && url !== null && _.isUndefined(url.errorCode)) {
                setdocusignURL(url.result);
                setShowDocuSign(true);
                setDocusign(true);
            } else {
                ErrorHandlingUtil.processErrorResponse(props, url, submission, currentPage);
            }
        }, (error) => {
            console.log(error);
        });
        return false;
    };

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        pmtConfirmationNumValue: {
            content: confirmationNumber
        },
        pmtAmountValue: {
            content: paymentAmount
        },
        docusignIframe: {
            src: docusignURL,
            height: breakpoint === 'phone' ? '650px' : '800px',
            width: '100%',
            showLoader: false,
            scrolling: 'no',
        },
        docusignDiv: {
            visible: docusignURL.length > 0
        },
        confirmationContainer: {
            visible: _.isEmpty(docusignURL)
        },
        sessiontimeoutcomponent: {
            signDocuments: signDocuments,
            docuSignInd: true,
            submissionObj: submission
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onSignDocuments: signDocuments
        },
        resolveComponentMap: {
            sessiontimeoutcomponent: SessionTimeoutComponent
        }
    };

    return isLoading ? (
        <Loader loaded={!isLoading} />
    ) : (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submission}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PaymentConfirmationPage.propTypes = {
};
PaymentConfirmationPage.defaultProps = {
};
export default withRouter(PaymentConfirmationPage);