import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes, { string } from 'prop-types';
import classNames from 'classnames';
import {
    Loader, Tooltip, IconButton, ModalService
} from '@jutro/components';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { withValidation, validationPropTypes } from 'gw-portals-validation-react';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import { withViewModelService, ViewModelForm } from 'gw-portals-viewmodel-react';
import { VehicleInfoLookupService } from 'gw-capability-vehicleinfo';
import { AddCoverablesService } from 'gw-capability-policycommon-alfa';
// actually using the Customer version (segmented changes) - see customer-config.js
import { AddressLookupComponent } from 'gw-capability-address-react';
import ErrorHandlingUtil from '../../../../pages/Utils/ErrorHandlingUtil';
import { VinPopUp } from 'gw-custom-widgets-alfa';
import { transformFlatToNestedMetadata } from '@jutro/uimetadata';
import metadata from './VehicleInfoLookupComponent.metadata.json5';
import styles from './VehicleInfoLookupComponent.module.scss';
import messages from './VehicleInfoLookupComponent.messages';

function VehicleInfoComponentTooltip() {
    const message = 'A VIN is a vehicle identification number that is specific to each vehicle. A VIN is composed of 17 characters and can usually be found on the driver\'s-side interior dash, on the title or on the vehicle registration.';
    return (
        <Tooltip
            animation="fade"
            content={message}
            delay={[
                0,
                40
            ]}
            duration={[
                300,
                300
            ]}
            flipBehavior={[
                'right',
                'bottom',
                'top',
                'left',
                'right'
            ]}
            followCursor={false}
            hideOnClick={false}
            id="tooltip"
            placement="top"
            showOnInit={false}
            sticky
            trigger="mouseenter"
        >
            <IconButton icon="fa-info-circle" aria-haspopup="true" className={styles.gwToolTip} aria-hidden="true" aria-label="Information" />
        </Tooltip>
    );
}

class VehicleInfoLookupComponent extends Component {
    /**
     * @memberof gw-capability-address-react.VehicleInfoLookupComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {function} propTypes.onValueChange - callback when change is made
     * @prop {string} propTypes.path - path to value in the view modal
     * @prop {boolean} propTypes.displayImage - flag for specifying if
     *  image of the vehicle should be displayed
     */
    static propTypes = {
        value: PropTypes.shape({ postalCode: PropTypes.string }),
        path: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        onValueChange: PropTypes.func,
        onValidate: PropTypes.func,
        createVehicle: PropTypes.func,
        removeVehicle: PropTypes.func,
        addVehicleVM: PropTypes.func,
        displayImage: PropTypes.bool,
        displayAllFields: PropTypes.bool,
        accountHolder: PropTypes.shape({}).isRequired,
        vehicles: PropTypes.shape({}).isRequired,
        quoteId: PropTypes.string.isRequired,
        layout: PropTypes.oneOf(['normal', 'reversed', 'full', 'indent']),
        labelPosition: PropTypes.oneOf(['left', 'right', 'top']),
        showVinInfoText: PropTypes.bool,
        showOptional: PropTypes.bool,
        showErrors: PropTypes.bool,
        authHeader: PropTypes.shape({}).isRequired,
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        garrageAddress: PropTypes.shape({}).isRequired,
        ...validationPropTypes
    };

    static defaultProps = {
        onValidate: undefined,
        value: undefined,
        onValueChange: undefined,
        createVehicle: undefined,
        removeVehicle: undefined,
        addVehicleVM: undefined,
        displayImage: true,
        displayAllFields: true,
        labelPosition: 'left',
        layout: 'normal',
        showVinInfoText: false,
        showOptional: true,
        showErrors: false
    };

    state = {
        showLoader: false,
        readonlyOverride: false,
        stockPhoto: undefined,
        showFieldsOverride: true,
        vehicleMakeList: [],
        vehicleModelList: [],
        vehicleTrimList: [],
        selectedMake: undefined,
        selectedModel: undefined,
        selectedYear: undefined,
        vinSelected: true,
        showVehicle: true,
        triggerStdProcess: false, // flag to trigger address standardization in the AddressLookupComponent
        addressStandardized: false, // is the garagingAddress standardized
        standardizationRan: false,  // Did the address standardization process run after being changed?
        // This should be true immediately after the process is run
        // This should be reset to false if the garaging address is false
        stdAddress: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            postalCode: '',
            county: '',
            standardizedType: ''
        },
        showPOBoxError: false,
        vinErrorCode: undefined,
        vinErrorMessage: undefined,
        isManualVinTriggered: false,
        isVinVerified: false,
        isVinBtnDisabled: false,
        showVinError: false,
        yearError: false,
        showCounty: false,
        defaultGarageAddress: {},
        invalidZip: false,
        error: false
    };

    componentDidMount() {
        const {
            id,
            onValidate,
            displayAllFields,
            isComponentValid,
            registerComponentValidation,
            value: vehicleData,
        } = this.props;

        registerComponentValidation(this.getIsFormValid);

        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        this.setState({ showFieldsOverride: displayAllFields });
        this.getMakeList();
        this.getModelList();

        if (_.isEmpty(vehicleData.vin.value) && !_.isEmpty(vehicleData.vehicleType.value)) {
            this.setState({ vinSelected: false });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            onValidate,
            id,
            isComponentValid,
            hasValidationChanged,
            value: newVehicleData
        } = this.props;

        const { value: oldVehicleData } = prevProps;
        const isSameVehicle = _.isEqual(newVehicleData.value, oldVehicleData.value);

        if (onValidate && (!isSameVehicle || hasValidationChanged)) {
            onValidate(isComponentValid, id);
        }
    }

    getIsFormValid = () => {
        const { value: vehicleData } = this.props;
        return vehicleData.aspects.subtreeValid && vehicleData.aspects.valid;
    };

    callModelPopup = () => {
        return ModalService.showPopover(VinPopUp, {
        }).result.then((result) => { });
    };

    getDivTooltipText = () => {
        return (
            <div className={styles.tooltipDivStyle} role="button" tabIndex="0" onKeyDown={this.callModelPopup} onClick={() => this.callModelPopup()}>
                <Tooltip
                    animation="fade"
                    placement="top"
                    delay={[
                        0,
                        40
                    ]}
                    flipBehavior={[
                        'right',
                        'bottom',
                        'top',
                        'left',
                        'right'
                    ]}
                    followCursor={false}
                    hideOnClick
                    id="tooltip"
                    showOnInit={false}
                    sticky
                    trigger="mouseenter"
                />
                <IconButton icon="fa-info-circle" aria-haspopup="true" iconClassName={styles.iconFontStyle} aria-hidden="true" aria-label="Information" />
            </div>
        );
    };

    setCarYears = () => {
        const currentYear = new Date().getUTCFullYear() + 1;
        const { oldestCarYear } = config.personalAutoConfig;

        // _.range is not inclusive. Need to add -1 to get correct years
        const yearRange = _.range(currentYear, oldestCarYear - 1);

        return yearRange.map(_.toString).map((year) => ({
            code: year,
            name: year
        }));
    };
    

    showYearError = () => {
        const { vehicleData } = this.props;
        if (!_.isEmpty(vehicleData) && !_.isEmpty(vehicleData.year.value)) {
            const year = Number(vehicleData.year.value);
            if (year < 1981 || year > new Date().getFullYear() + 1) {
                this.setState({
                    yearError: true
                });
                return true;
            }
        }
        return false;
    };


    getVehicleTypeList = () => {
        const typeList = [
            { code: 'VN', name: 'Van' },
            { code: 'PU', name: 'Truck/SUV' },
            { code: 'PV', name: 'Car' }];
        return typeList;
    };

    vinSelect = () => {
        this.setState({ vinSelected: true });
    };

    ymmSelect = () => {
        this.setState({ vinSelected: false });
    };

    getMakeList = () => {
        const { value: vehicleData, callErrorHandler } = this.props;

        if (!_.isEmpty(vehicleData.vehicleType.value) && vehicleData.year.value) {
            const vehicleLookupRequestDTO = {
                year: vehicleData.year.value,
                vehicleType: vehicleData.vehicleType.value.code
            };
            VehicleInfoLookupService.lookupMakes(vehicleLookupRequestDTO).then((makeList) => {
                if (!_.isEmpty(makeList.errorCode)) {
                    if (makeList.errorCode.includes('006') || makeList.errorCode.includes('007')) {
                        callErrorHandler(makeList);
                    }
                } else {
                    const vehicleMakes = makeList.makes.map((make) => (
                        { code: make.itemCode, name: make.itemDescription }));
                    this.setState({ vehicleMakeList: vehicleMakes });
                }
            }).catch((error) => {
                callErrorHandler(error);
            });
        }
    };

    getModelList = () => {
        const { value: vehicleData, callErrorHandler } = this.props;

        if (!_.isEmpty(vehicleData.vehicleType.value) && vehicleData.year.value) {
            const vehicleLookupRequestDTO = {
                year: vehicleData.year.value,
                vehicleType: vehicleData.vehicleType.value.code,
                make: vehicleData.make.value,
            };
            VehicleInfoLookupService.lookupModels(vehicleLookupRequestDTO).then((modelList) => {
                if (!_.isEmpty(modelList.errorCode)) {
                    if (modelList.errorCode.includes('006') || modelList.errorCode.includes('007')) {
                        callErrorHandler(modelList);
                        this.setState({ vehicleModelList: [] });
                    }
                } else {
                    const vehicleModels = modelList.makes.map((model) => (
                        { code: model.itemCode, name: model.itemDescription }));
                    this.setState({ vehicleModelList: vehicleModels });
                }
            }).catch((error) => {
                this.setState({ vehicleModelList: [] });
                callErrorHandler(error);
            });
        }
    };

    reverseLookup = () => {
        const { value: vehicleData, onValueChange, callErrorHandler, quoteId } = this.props;

        this.setState({ showLoader: true });
        VehicleInfoLookupService.reverseLookup(vehicleData.year.value, vehicleData.make.value,
            vehicleData.model.value, quoteId).then((vehicleInfo) => {
                if (!_.isEmpty(vehicleInfo.errorCode)) {
                    if (vehicleInfo.errorCode.includes('006') || vehicleInfo.errorCode.includes('007')) {
                        //  Errorhandling custom contact redirection to be done in future SC
                    }
                }
                let vehicleTrim = [];
                if (!_.isEmpty(vehicleInfo.makes)) {
                    vehicleTrim = vehicleInfo.makes.map((trim) => (
                        { code: trim.itemCode, name: trim.itemDescription }));
                }

                this.setState({ vehicleTrimList: vehicleTrim });
                const vinResponse = vehicleInfo.vinLookupResponse;
                if (!_.isEmpty(vinResponse)) {
                    _.set(vehicleData, 'isProhibited_alfa', vinResponse.alfaProhibited);
                    _.set(vehicleData, 'isPotentialProhibited_alfa', vinResponse.alfaPotentialProhibited);
                    this.handleChange(vinResponse.alfaProhibited, 'isProhibited_alfa');
                    this.handleChange(vinResponse.alfaPotentialProhibited, 'isPotentialProhibited_alfa');
                    _.set(vehicleData, 'rateSymbolCollision_alfa', vinResponse.isocollisionSymbol);
                    this.handleChange(vinResponse.isocollisionSymbol, 'rateSymbolCollision_alfa');
                    if (!_.isEmpty(vinResponse.rapaResult)) {
                        _.set(vehicleData, 'rapaVersion', vinResponse.rapaVersion);
                        this.handleChange(vinResponse.rapaVersion, 'rapaVersion');
                        _.set(vehicleData, 'rapaResult', vinResponse.rapaResult);
                        this.handleChange(vinResponse.rapaResult, 'rapaResult');
                    }

                    if (!_.isEmpty(vinResponse.ratingCostSymbol)) {
                        _.set(vehicleData, 'rateSymbol_alfa', vinResponse.ratingCostSymbol);
                        this.handleChange(vinResponse.ratingCostSymbol, 'rateSymbol_alfa');
                    } else {
                        _.set(vehicleData, 'rateSymbol_alfa', vinResponse.isocomprehensiveSymbol);
                        this.handleChange(vinResponse.isocomprehensiveSymbol, 'rateSymbol_alfa');
                    }
                }
                onValueChange(vehicleData.value, '');
                this.setState({ showLoader: false });
            }).catch((error) => {
                
                this.setState({ vehicleTrimList: [] });
                callErrorHandler(error);
            });
    };

    resetDependentField = (changePath) => {
        const { path, onValueChange } = this.props;
        let cleanPath = [];
        switch (changePath) {
            case 'vin':
                cleanPath = ['vehicleType', 'year', 'make', 'model', 'trim'];
                this.setState({ selectedVehicleType: undefined });
                this.setState({ vehicleMakeList: [] });
                this.setState({ vehicleModelList: [] });
                this.setState({ vehicleTrimList: [] });
                this.setState({ selectedMake: undefined });
                this.setState({ selectedModel: undefined });
                this.setState({ selectedYear: undefined });
                break;
            case 'vehicleType':
                cleanPath = ['year', 'make', 'model', 'trim'];
                this.setState({ vehicleMakeList: [] });
                this.setState({ vehicleModelList: [] });
                this.setState({ vehicleTrimList: [] });
                this.setState({ selectedMake: undefined });
                this.setState({ selectedModel: undefined });
                this.setState({ selectedYear: undefined });
                break;
            case 'year':
                cleanPath = ['make', 'model', 'trim'];
                this.setState({ vehicleMakeList: [] });
                this.setState({ vehicleModelList: [] });
                this.setState({ vehicleTrimList: [] });
                this.setState({ selectedMake: undefined });
                this.setState({ selectedModel: undefined });
                break;
            case 'make':
                cleanPath = ['model', 'trim'];
                this.setState({ vehicleModelList: [] });
                this.setState({ vehicleTrimList: [] });
                this.setState({ selectedModel: undefined });
                break;
            case 'model':
                cleanPath = ['trim'];
                this.setState({ vehicleTrimList: [] });
                break;
            default:
                break;
        }
        cleanPath.forEach((cpath) => {
            onValueChange(undefined, `${path}.${cpath}`);
        });
    };

    setGaragingAddressToAccountAddress = () => {
        // Set the garaging address to be the same as the accountHolder primary address
        const {
            path,
            onValueChange,
            accountHolder,
            value: vehicleData
        } = this.props;
        const { primaryAddress } = accountHolder;
        _.set(vehicleData, 'garagingAddress.addressLine1.value', primaryAddress.addressLine1);
        _.set(vehicleData, 'garagingAddress.addressLine2.value', primaryAddress.addressLine2);
        _.set(vehicleData, 'garagingAddress.city.value', primaryAddress.city);
        _.set(vehicleData, 'garagingAddress.county.value', primaryAddress.county);
        _.set(vehicleData, 'garagingAddress.state.value', primaryAddress.state);
        _.set(vehicleData, 'garagingAddress.postalCode.value', primaryAddress.postalCode);
        _.set(vehicleData, 'garagingAddress.displayName.value', primaryAddress.displayName);
        _.set(vehicleData, 'garagingAddress.standardizedType.value', primaryAddress.standardizedType);
        _.set(vehicleData, 'garagingAddress.spatialPoint.value', primaryAddress.spatialPoint);
        _.set(vehicleData, 'garagingAddress.censusBlock.value', primaryAddress.censusBlock);
    }

    updateStandardizationRan = (stdProcessDidRun, updatedAddress) => {
        // called by AddressLookupComponent once Standardization process has run
        const {
            value: vehicleData
        } = this.props;
        const { showCounty } = this.state;

        if (updatedAddress !== undefined && updatedAddress !== null) {
            _.set(vehicleData, 'garagingAddress.addressLine1.value', updatedAddress.addressLine1);
            _.set(vehicleData, 'garagingAddress.addressLine2.value', updatedAddress.addressLine2);
            _.set(vehicleData, 'garagingAddress.city.value', updatedAddress.city);
            _.set(vehicleData, 'garagingAddress.county.value', updatedAddress.county);
            _.set(vehicleData, 'garagingAddress.postalCode.value', updatedAddress.postalCode);
            _.set(vehicleData, 'garagingAddress.state.value', updatedAddress.stateTC);
            _.set(vehicleData, 'garagingAddress.displayName.value', updatedAddress.displayName);
            _.set(vehicleData, 'garagingAddress.standardizedType.value', updatedAddress.standardizedType);
            _.set(vehicleData, 'garagingAddress.spatialPoint.value', updatedAddress.spatialPoint);
            _.set(vehicleData, 'garagingAddress.censusBlock.value', updatedAddress.censusBlock);
        }

        if (stdProcessDidRun === true) {
            if (updatedAddress !== undefined && updatedAddress.standardizedType === 'Standardized') {
                this.setState({
                    standardizationRan: true,
                    triggerStdProcess: false, // turn off the Address Standardization trigger
                    addressStandardized: true,
                    stdAddress: updatedAddress,
                    showCounty: false
                });
            } else if (updatedAddress !== undefined && updatedAddress.standardizedType !== 'Standardized') {
                const address = _.get(vehicleData, 'garagingAddress');
                const isPOBoxAddress = this.checkForPOBoxAddress(address);
                const showCountyError = updatedAddress.county === undefined;
                this.setState({
                    standardizationRan: true,
                    triggerStdProcess: false, // turn off the Address Standardization trigger
                    showPOBoxError: isPOBoxAddress,
                    addressStandardized: false,
                    showCounty: showCountyError
                });
            } else {
                this.setState({
                    standardizationRan: true,
                    stdAddress: updatedAddress,
                    triggerStdProcess: false, // turn off the Address Standardization trigger
                    addressStandardized: false,
                    showCounty: true
                });
            }
        } else {
            this.setState({
                triggerStdProcess: false,
                addressStandardized: false, // turn off the Address Standardization trigger
                showCounty: false
            });
        }
        this.forceUpdate();
        if (updatedAddress.county !== undefined) {
            this.addVehicleCoverable();
        }
    };

    getTriggerStdProcess = () => {
        const { triggerStdProcess } = this.state;
        return triggerStdProcess;
    }

    getShowCounty = () => {
        const { showCounty } = this.state;
        return showCounty;
    }

    standardizeGarageAddr = () => {
        // Standardize Garaging address
        this.setState({ triggerStdProcess: true });
        this.forceUpdate();
    }

    checkGaragingAddress = () => {
        const { value: vehicleData, accountHolder } = this.props;
        const { primaryAddress } = accountHolder;
        const { addressStandardized, stdAddress, standardizationRan } = this.state;

        // check the garaging address - see if it needs to be standardized
        let updateFlag = false;
        if (!addressStandardized && !standardizationRan) {
            return;
        }
        if (stdAddress.addressLine1 === '' || stdAddress.city === '' || stdAddress.state === ''
            || stdAddress.postalCode === '' || stdAddress.county === '') {
            return;
        }
        if (_.get(vehicleData, 'garagingAddress.addressLine1.value') !== stdAddress.addressLine1) { updateFlag = true; }
        if ((_.get(vehicleData, 'garagingAddress.addressLine2.value') !== undefined) && (stdAddress.addressLine2 !== undefined)) {
            if (_.get(vehicleData, 'garagingAddress.addressLine2.value') !== stdAddress.addressLine2) { updateFlag = true; }
        }
        if (_.get(vehicleData, 'garagingAddress.city.value') !== stdAddress.city) { updateFlag = true; }
        if (_.get(vehicleData, 'garagingAddress.state.value.code') !== stdAddress.state) { updateFlag = true; }
        if (_.get(vehicleData, 'garagingAddress.postalCode.value') !== stdAddress.postalCode) { updateFlag = true; }
        if (_.get(vehicleData, 'garagingAddress.county.value') !== stdAddress.county) { updateFlag = true; }
        if (_.get(vehicleData, 'garagingAddress.standardizedType.value') !== stdAddress.standardizedType) { updateFlag = true; }
        if (updateFlag) {
            this.setState({
                addressStandardized: false,
                standardizationRan: false
            });
        }
    }

    addVehicleCoverable = async () => {
        const {
            value: vehicleData, createVehicle, quoteId, onValueChange, addVehicleVM, vehicles, callErrorHandler
        } = this.props;
        if (!vehicleData.isPrefillVehicle.value) {
            // Creating add vehicle request object
            if (_.isUndefined(vehicleData.costNew.value)) {
                _.set(vehicleData, 'costNew.value', {});
            }
            if (!_.isUndefined(vehicleData.vin.value)) {
                _.set(vehicleData, 'vin.value', vehicleData.vin.value.toUpperCase());
            }
            const addVehicleRequestDTO = {
                quoteId: quoteId,
                vehicle: vehicleData.value
            };
            this.setState({ showLoader: true });
            // Calling Add coverable service
            await AddCoverablesService.addVehicle(addVehicleRequestDTO).then((response) => {
                _.set(vehicleData, 'publicID', response.vehicle.publicID);
                _.set(vehicleData, 'isSelectedVehicle', true);

                onValueChange(vehicleData.value, '');
                vehicles.map((object) => {
                    if (_.isUndefined(object.vin) && _.isUndefined(object.make)
                        && _.isUndefined(object.model)) {
                        this.setState({
                            showVehicle: false
                        });
                    }
                    return object;
                });
                const { showVehicle } = this.state;
                if (showVehicle) {
                    createVehicle();
                }

                const { addressStandardized } = this.state;
                if (addressStandardized) {
                    _.set(vehicleData, 'isOpen', false);
                } else {
                    _.set(vehicleData, 'isOpen', true);
                }

                addVehicleVM();
            }, (error) => {
                console.log(error);
                this.setState({ showLoader: false });
                callErrorHandler(error, true);
            });
            this.setState({ showLoader: false });
        }
        if (vehicleData.isPrefillVehicle.value) {
            this.validateVin();
        }
    }

    addVehicle = () => {
         if(!this.disableAddButton()){
        const {
            value: vehicleData
        } = this.props;
        const {
            addressStandardized,
            standardizationRan
        } = this.state;
        const address = _.get(vehicleData, 'garagingAddress');
        const isPOBoxAddress = this.checkForPOBoxAddress(address);
        this.setState({ showPOBoxError: isPOBoxAddress });
        if (isPOBoxAddress === false) {
            this.checkGaragingAddress();
            if (vehicleData.isVehiclePrimarilyKeptAtAHAddress.value === false && !addressStandardized && !standardizationRan) {
                this.standardizeGarageAddr();
            } else {
                this.addVehicleCoverable();
                _.set(vehicleData, 'isOpen', false);
                this.handleChange(false, 'isOpen');
            }
        }
        }    
    };

    clearVehicle = () => {
        // Invoked when 'Cancel' button clicked when adding a vehicle
        const {
            value: vehicleData, accountHolder, onValueChange, removeVehicle, garrageAddress, vinNumber, lease, isVehiclePrimarilyKeptAtAddress
        } = this.props;
        const { primaryAddress } = accountHolder;
        const { addressStandardized } = this.state;

        this.setState({
            vinErrorCode: undefined,
            vinErrorMessage: undefined,
            isManualVinTriggered: false,
            isVinVerified: false,
            isVinBtnDisabled: false,
            showVinError: false
        });

        if (vehicleData.isSelectedVehicle.value) {
            _.set(vehicleData, 'vin.value', vinNumber);
            _.set(vehicleData, 'leaseOrRent', lease);
            this.validateVin();
            _.set(vehicleData, 'isVehiclePrimarilyKeptAtAHAddress', isVehiclePrimarilyKeptAtAddress);
            if (!addressStandardized && !isVehiclePrimarilyKeptAtAddress) {
                _.set(vehicleData, 'garagingAddress.addressLine1.value', garrageAddress.addressLine1);
                _.set(vehicleData, 'garagingAddress.addressLine2.value', garrageAddress.addressLine2);
                _.set(vehicleData, 'garagingAddress.city.value', garrageAddress.city);
                _.set(vehicleData, 'garagingAddress.state.value', garrageAddress.state);
                _.set(vehicleData, 'garagingAddress.postalCode.value', garrageAddress.postalCode);
                _.set(vehicleData, 'garagingAddress.county.value', garrageAddress.county);
                _.set(vehicleData, 'garagingAddress.country.value', garrageAddress.country);
            }
        }
        if (!vehicleData.isSelectedVehicle.value) {
            _.set(vehicleData, 'isVehiclePrimarilyKeptAtAHAddress', true);
            _.set(vehicleData, 'leaseOrRent', false);
            this.setGaragingAddressToAccountAddress();
        }
        const emptyAddress = {
            addressLine1: '',
            city: '',
            state: '',
            postalCode: '',
            county: '',
            standardizedType: ''
        };
        this.setState({
            triggerStdProcess: false,
            addressStandardized: false,
            stdAddress: emptyAddress,
            isManualVinTriggered: false,
            isVinVerified: false,
            isVinBtnDisabled: false
        });

        if (!vehicleData.isPrefillVehicle.value && !vehicleData.isSelectedVehicle.value) {
            vehicleData.vin.value = undefined;
            vehicleData.year.value = undefined;
            vehicleData.make.value = undefined;
            vehicleData.model.value = undefined;
            vehicleData.vehicleTrim.value = undefined;
            vehicleData.bodyType.value = undefined;
            vehicleData.vehicleType.value = undefined;
        }

        _.set(vehicleData, 'isOpen', false);
        this.handleChange(false, 'isOpen');

        onValueChange(vehicleData.value, '');
        if (!vehicleData.isPrefillVehicle.value && vehicleData.isSelectedVehicle.value) {
            removeVehicle('', vehicleData.value, false, 'fromCancel');
        }
        if (vehicleData.isPrefillVehicle.value) {
            removeVehicle('', vehicleData.value, true);
        }
        if (!vehicleData.isPrefillVehicle.value && !vehicleData.isSelectedVehicle.value) {
            this.setState({ vinSelected: true });
            removeVehicle('', vehicleData.value, true);
        }
    }

    handleChange = (value, changedPath) => {
        const {
            path, onValueChange, accountHolder, value: vehicleData
        } = this.props;
        const {
            addressStandardized, stdAddress, isVinBtnDisabled, isManualVinTriggered
        } = this.state;
        let valueCode;
        if (value !== undefined) {
            valueCode = value;
        }
        switch (changedPath) {
            case 'vin':
                onValueChange(value, `${path}.${changedPath}`);
                this.resetDependentField(changedPath);
                if (value !== undefined && value.length === 17) {
                    if (vehicleData.isPrefillVehicle.value) {
                        this.validateVin();
                    } else {
                        if (isVinBtnDisabled) {
                            this.setState({
                                isVinBtnDisabled: false,
                                showVinError: false
                            });
                        }

                        if (!isManualVinTriggered) {
                            this.setState({ isManualVinTriggered: true });
                            this.validateVin();
                        }
                        this.setState({
                            vinErrorCode: undefined,
                            vinErrorMessage: undefined
                        });
                    }
                } else if (!vehicleData.isPrefillVehicle.value && isVinBtnDisabled) {
                    this.setState({
                        isVinBtnDisabled: false,
                        showVinError: false
                    });
                }
                break;
            case 'vehicleType':
                onValueChange(valueCode, `${path}.${changedPath}`);
                this.setState({ selectedVehicleType: valueCode });
                this.resetDependentField(changedPath);
                break;
            case 'year':
                onValueChange(valueCode, `${path}.${changedPath}`);
                this.setState({ selectedYear: valueCode });
                this.getMakeList();
                this.resetDependentField(changedPath);
                break;
            case 'make':
                onValueChange(valueCode, `${path}.${changedPath}`);
                this.setState({ selectedMake: valueCode });
                this.getModelList();
                this.resetDependentField(changedPath);
                break;
            case 'model':
                onValueChange(valueCode, `${path}.${changedPath}`);
                this.setState({ selectedModel: valueCode });
                this.reverseLookup();
                this.resetDependentField(changedPath);
                break;
            case 'isVehiclePrimarilyKeptAtAHAddress':
                // If address will be same as Primary - set to be same as primary
                if (value === true) {
                    this.setGaragingAddressToAccountAddress();
                    this.setState({ showPOBoxError: false, invalidZip: false, error: false });
                } else {
                    _.set(vehicleData, 'garagingAddress.county.value', undefined);
                    _.set(vehicleData, 'garagingAddress.addressLine1.value', undefined);
                    _.set(vehicleData, 'garagingAddress.addressLine2.value', undefined);
                    _.set(vehicleData, 'garagingAddress.city.value', undefined);
                    _.set(vehicleData, 'garagingAddress.postalCode.value', undefined);
                }
                onValueChange(value, `${path}.${changedPath}`);
                break;
            case 'garagingAddress.addressLine1':
                if (addressStandardized) {
                    if (value !== stdAddress.addressLine1) {
                        this.setState({ addressStandardized: false });
                    }
                }
                onValueChange(value, `${path}.${changedPath}`);
                break;
            case 'garagingAddress.addressLine2':
                if (addressStandardized) {
                    if (value !== stdAddress.addressLine2) { this.setState({ addressStandardized: false }); }
                }
                // Handled differenently because it is a FloatComponent
                onValueChange(value, `${path}.${changedPath}.value`);
                break;
            case 'garagingAddress.city':
                if (addressStandardized) {
                    if (value !== stdAddress.city) { this.setState({ addressStandardized: false }); }
                }
                // Handled differenently because it is a FloatComponent
                onValueChange(value, `${path}.${changedPath}.value`);
                break;
            case 'garagingAddress.postalCode':
                if (addressStandardized) {
                    if (value !== stdAddress.postalCode) { this.setState({ addressStandardized: false }); }
                }
                // Handled differenently because it is a FloatComponent
                onValueChange(value, `${path}.${changedPath}.value`);
                break;
            case 'garagingAddress.county':
                if (addressStandardized) {
                    if (value !== stdAddress.county) { this.setState({ addressStandardized: false }); }
                }
                // Handled differenently because it is a FloatComponent
                onValueChange(value, `${path}.${changedPath}.value`);
                break;
            case 'garagingAddress.state':
                if (addressStandardized) {
                    if (value !== stdAddress.state) { this.setState({ addressStandardized: false }); }
                }
                // Handled differenently because it is a custom component
                onValueChange(value, `${path}.${changedPath}.value`);
                break;
            default:
                onValueChange(value, `${path}.${changedPath}`);
                break;
        }
    };

    validateVin = () => {
        const {
            value: vehicleData, vehicles, authHeader, onValueChange, path, quoteId, addVehicleVM, callErrorHandler
        } = this.props;

        const vinNumber = _.get(vehicleData, 'vin.value');
        let currentVinError;
        if (!_.isEmpty(vehicleData.vin.value) && vehicleData.vin.value.length === 17) {
            const vehicleMatch = _.find(vehicles, (v) => {
                if (!_.isEmpty(v.vin) && v.publicID !== vehicleData.publicID.value) {
                    return ((v.vin === vehicleData.vin.value) && (v.isSelectedVehicle === true));
                }
                return undefined;
            });

            if (!_.isEmpty(vehicleMatch)) {
                currentVinError = {
                    vinErrorCode: '010',
                    vinErrorMessage: 'This VIN is already added for a vehicle'
                };
                this.setState({
                    isManualVinTriggered: false
                });
            } else {
                this.setState({ showLoader: true });

                VehicleInfoLookupService.lookupVehicleInfoBasedOnVin(vinNumber.toUpperCase(), quoteId, authHeader)
                    .then((vehicleInfo) => {
                        if (vehicleInfo && (vehicleInfo.errorCode === '006' || vehicleInfo.errorCode === '007')) {
                            callErrorHandler('006');
                        }
                        const vehicleMake = _.get(vehicleInfo.vinLookupResponse, 'make', '');
                        const vehicleModel = _.get(vehicleInfo.vinLookupResponse, 'model', '');
                        const vehicleYear = _.get(vehicleInfo.vinLookupResponse, 'year', '');
                        const vehicleType = _.get(vehicleInfo.vinLookupResponse, 'alfaVehicleType', '');

                        const vinResponse = vehicleInfo.vinLookupResponse;
                        if (!_.isEmpty(vinResponse)) {
                            if (!_.isEmpty(vinResponse.errorCode)) {
                                this.setState({
                                    vinErrorCode: vinResponse.errorCode,
                                    vinErrorMessage: vinResponse.errorDescription,
                                    isVinBtnDisabled: true,
                                    showVinError: true
                                });
                                this.setState({ showLoader: false });
                            } else {
                                const vehicleTypeList = this.getVehicleTypeList();
                                const name = _.find(vehicleTypeList, (val) => {
                                    return (vehicleType.indexOf(val.code) > -1);
                                });
                                onValueChange(vehicleType, `${path}.vehicleType`);
                                this.setState({ selectedVehicleType: name.name });
                                onValueChange(vehicleYear, `${path}.year`);
                                this.setState({ selectedYear: vehicleYear });
                                onValueChange(vehicleMake, `${path}.make`);
                                this.setState({ selectedMake: vehicleMake });
                                onValueChange(vehicleModel, `${path}.model`);
                                this.setState({ selectedModel: vehicleModel });
                                _.set(vehicleData, 'bodyType', vinResponse.bodyStyle);
                                _.set(vehicleData, 'isProhibited_alfa', vinResponse.alfaProhibited);
                                this.handleChange(vinResponse.alfaProhibited, 'isProhibited_alfa');
                                _.set(vehicleData, 'isPotentialProhibited_alfa', vinResponse.alfaPotentialProhibited);
                                _.set(vehicleData, 'rateSymbolCollision_alfa', vinResponse.isocollisionSymbol);
                                if (!_.isEmpty(vinResponse.rapaResult)) {
                                    const { rapaResult } = vinResponse;
                                    _.set(vehicleData, 'rapaVersion', vinResponse.rapaVersion);
                                    _.set(vehicleData, 'rapaResult', rapaResult);
                                }

                                if (!_.isEmpty(vinResponse.ratingCostSymbol)) {
                                    _.set(vehicleData, 'rateSymbol_alfa', vinResponse.ratingCostSymbol);
                                } else {
                                    _.set(vehicleData, 'rateSymbol_alfa', vinResponse.isocomprehensiveSymbol);
                                }

                                this.setState({
                                    isVinBtnDisabled: true,
                                    isVinVerified: true,
                                    showVinError: false
                                });
                                if (vehicleData.isPrefillVehicle.value) {
                                    if (_.isUndefined(vehicleData.costNew.value)) {
                                        _.set(vehicleData, 'costNew.value', {});
                                    }
                                    if (!_.isUndefined(vehicleData.vin.value)) {
                                        _.set(vehicleData, 'vin.value', vehicleData.vin.value.toUpperCase());
                                    }
                                    const addVehicleRequestDTO = {
                                        quoteId: quoteId,
                                        vehicle: vehicleData.value
                                    };

                                    // Calling Add coverable service
                                    AddCoverablesService.addVehicle(addVehicleRequestDTO).then((response) => {
                                        _.set(vehicleData, 'publicID', response.vehicle.publicID);
                                        _.set(vehicleData, 'isSelectedVehicle', true);
                                        onValueChange(true, 'isSelectedVehicle');
                                        addVehicleVM();
                                        this.setState({ showLoader: false });
                                    }, (error) => {
                                        console.log(error);
                                        callErrorHandler(error, true);
                                    });
                                } else {
                                    this.setState({ showLoader: false });
                                }
                            }
                        } else {
                            this.setState({
                                vinErrorCode: vehicleInfo.errorCode,
                                vinErrorMessage: vehicleInfo.errorDescription,
                                isVinBtnDisabled: true,
                                showVinError: true
                            });
                            this.setState({ showLoader: false });
                        }
                    }).catch((error) => {
                        
                        this.setState({ showLoader: false });
                        callErrorHandler(error);
                    });
            }
        } else if (!_.isEmpty(vehicleData.vin.value) && vehicleData.vin.value !== '') {
            currentVinError = {
                vinErrorCode: '008',
                vinErrorMessage: 'We\'re sorry. We\'re unable to verify VIN.'
            };
        } else if (vehicleData.vin.value === null || vehicleData.vin.value === '' || vehicleData.vin.value === undefined) {
            currentVinError = {
                vinErrorCode: '000',
                vinErrorMessage: 'Please enter a VIN'
            };
        }
        if (currentVinError !== undefined && !vehicleData.isPrefillVehicle.value) {
            onValueChange(vehicleData.value, '');

            setTimeout(() => {
                this.setState({
                    vinErrorCode: currentVinError.vinErrorCode,
                    vinErrorMessage: currentVinError.vinErrorMessage,
                    isVinBtnDisabled: true,
                    showVinError: true
                });
            }, 10);

            this.setState({ showLoader: false });
        }
    };

    getAddressStandardized = () => {
        const { addressStandardized } = this.state;
        return addressStandardized;
    }

    getShowPOBoxError = () => {
        const { showPOBoxError } = this.state;
        return showPOBoxError;
    }

    fieldIsUnspecified = (fieldInQuestion) => {
        let result = false;
        if (fieldInQuestion === undefined || fieldInQuestion === null) {
            result = true;
        } else if (fieldInQuestion.value === '') {
            result = true;
        }
        return result;
    }

    checkForPOBoxAddress = (address) => {
        // const poBoxRegex = new RegExp('^\\s*(.*((p|post)[-.\\s]*(o|off|office)[-.\\s]*(b|box)[-.\\s]*)|.*((p|post)[-.\\s]*(o|off|office)[-.\\s]*)|.*((p|post)[-.\\s]*(b|box)[-.\\s]*)|(box)[-.\\s]*)(#|n|num|number)?\\s*\\d+', 'i');
        const poBoxRegex = new RegExp('(.*)?(P(OST)?\\.?\\s*O(FF)?(ICE)?\\.?\\s*BOX)(.*)', 'i'); // Same Regex as PolicyCenter-> AddressLookupHandler.gs
        if (address.addressLine1 !== undefined && address.addressLine1 !== null) {
            if (address.addressLine1.value !== undefined) {
                if (address.addressLine1.value.match(poBoxRegex)) {
                    return true;
                }
            }
        }
        if (address.addressLine2 !== undefined && address.addressLine2 !== null) {
            if (address.addressLine2.value !== undefined) {
                if (address.addressLine2.value.match(poBoxRegex)) {
                    return true;
                }
            }
        }
        return false;
    };

    disableAddButton = () => {
        const { value: vehicleData } = this.props;
        const { garagingAddress } = vehicleData;
        let { showErrors } = this.props;
        const { invalidZip } = this.state;
        let anyProblems = false;
        if (!vehicleData.aspects.valid) { anyProblems = true; }
        if (!vehicleData.aspects.subtreeValid) { anyProblems = true; }
        if (!garagingAddress.aspects.valid) { anyProblems = true; }
        if (!garagingAddress.aspects.subtreeValid) { anyProblems = true; }
        if (this.fieldIsUnspecified(garagingAddress.addressLine1)) { anyProblems = true; }
        if (this.fieldIsUnspecified(garagingAddress.city)) { anyProblems = true; }
        if (this.fieldIsUnspecified(garagingAddress.state)) { anyProblems = true; }
        if (this.fieldIsUnspecified(garagingAddress.postalCode)) { anyProblems = true; }
        if (_.isEmpty(garagingAddress.county.value) ) { anyProblems = true; }
        if (invalidZip) {
            anyProblems = true;
        }

        if (anyProblems) {
            showErrors = true;
        } else {
            showErrors = false;
        }
        this.setState({error : anyProblems})
        return anyProblems;
    }

    getVinError = () => {
        const { vinErrorCode, vinErrorMessage } = this.state;
        const unableToVerifyCode = ['008', '005', '009'];
        const unableToVerify = unableToVerifyCode.includes(vinErrorCode);
        const unabletoVerifyText = 'We\'re sorry. We\'re unable to verify VIN. Please re-enter or add by ';
        return (
            <div>
                {unableToVerify ? (
                    <span>
                        {unabletoVerifyText}
                        <a className={styles.errorRef} onClick={this.ymmSelect}>
                            Year, Make, Model
                        </a>
                    </span>
                ) : (
                        <span>
                            {vinErrorMessage}
                        </span>
                    )
                }
            </div>
        );
    }

    render() {
        const {
            showLoader, readonlyOverride, stockPhoto, showFieldsOverride, selectedVehicleType,
            selectedMake, selectedModel, vehicleMakeList, vehicleModelList, selectedYear, vinSelected,
            triggerStdProcess, addressStandardized, yearError, vinErrorCode, isVinBtnDisabled, isVinVerified, showVinError, error
        } = this.state;
        const {
            value: vehicleData,
            displayImage,
            layout,
            labelPosition,
            showVinInfoText,
            showOptional,
            accountHolder,
            showErrors
        } = this.props;
        const { primaryAddress } = accountHolder;
        const addressLine2 = _.isEmpty(primaryAddress.addressLine2) ? '' : primaryAddress.addressLine2;

        let vehicleDetails = '';
        // eslint-disable-next-line max-len
        if (!_.isEmpty(vehicleData.year.value) && !_.isEmpty(vehicleData.make.value) && !_.isEmpty(vehicleData.model.value)) {
            vehicleDetails = vehicleData.bodyType.value !== undefined ? `${vehicleData.year.value} ${vehicleData.make.value} ${vehicleData.model.value} ${vehicleData.bodyType.value}`
                : `${vehicleData.year.value} ${vehicleData.make.value} ${vehicleData.model.value}`;
        }
        let overrideYear = selectedYear;
        let overrideMake = selectedMake;
        let overrideModel = selectedModel;
        let showYearError = false;
        if (!_.isEmpty(selectedYear) && Number(selectedYear) < 1981 || Number(selectedYear) > new Date().getFullYear() + 1) {
            showYearError = true;
        }
        if (vehicleData.isSelectedVehicle.value) {
            overrideYear = vehicleData.year.value;
            overrideMake = vehicleData.make.value;
            overrideModel = vehicleData.model.value;
        }

        const showInvalidZip = (invalidZip) => {
            this.setState({ invalidZip: invalidZip});
        };
        
        const overrideProps = {
            '@field': {
                showOptional: showOptional,
                layout: layout,
                labelPosition: labelPosition
            },
            vinInfoContainer: {
                // eslint-disable-next-line max-len
                visible: showVinInfoText && !showFieldsOverride && !vehicleData.isPrefillVehicle.value
            },
            tooltipDiv: {
                content: this.getDivTooltipText()
            },
            vinSelection: {
                visible: !vehicleData.isPrefillVehicle.value
            },
            leaseRentToggle: {
                labelPosition: 'top'
            },
            vinAddSelection: {
                visible: !vehicleData.isPrefillVehicle.value && vinSelected
            },
            ymmSelection: {
                visible: !vinSelected,
                className: 'vinYmmInputDiv'
            },
            prefillVin: {
                value: vehicleData.vin.value,
                labelPosition: 'top',
                className: 'vinInfoSpan',
                visible: vehicleData.isPrefillVehicle.value
            },
            vinText: {
                content: (vehicleData.vin.value !== undefined) ? vehicleDetails : '',
                visible: !vehicleData.isPrefillVehicle.value && vinErrorCode === undefined
            },
            vinPrefillText: {
                content: vehicleDetails,
                visible: vehicleData.isPrefillVehicle.value
            },
            vinError: {
                content: vinErrorCode !== undefined && showVinError ? this.getVinError() : ''
            },
            vinButton: {
                onClick: () => this.validateVin(),
                disabled: isVinBtnDisabled,
                content: !isVinVerified ? 'Verify VIN' : 'Re-verify VIN'
            },
            vehicleMake: {
                readOnly: readonlyOverride,
                visible: !vehicleData.isPrefillVehicle.value,
                overrideTypeName: overrideMake,
                availableValues: vehicleMakeList,
                className: 'vinYmmInputDiv',
                showErrors: showErrors
            },
            vehicleModel: {
                readOnly: readonlyOverride,
                visible: !vehicleData.isPrefillVehicle.value,
                overrideTypeName: overrideModel,
                availableValues: vehicleModelList,
                className: 'vinYmmInputDiv',
                showErrors: showErrors
            },
            yearInputDiv: {
                visible: !vehicleData.isPrefillVehicle.value,
                className: showYearError ? 'floatInputError' : ''
            },
            vehicleYear: {
                readOnly: readonlyOverride,
                visible: !vehicleData.isPrefillVehicle.value,
                overrideTypeName: overrideYear,
                availableValues: this.setCarYears(),
                // className: 'vinYmmInputDiv',
                yearError: yearError,
                className: showYearError ? 'vinYmmInputDiv floatInputError' : 'vinYmmInputDiv',
                showErrors: showErrors,
                allowNew: true
            },
            yearError: {
                visible: showYearError
            },
            vehicleType: {
                availableValues: this.getVehicleTypeList(),
                overrideTypeName: selectedVehicleType,
                visible: !vehicleData.isPrefillVehicle.value,
                className: 'vinYmmInputDiv',
                showErrors: showErrors
            },
            addressFirstContainer: {
                // visible: vehicleData.isVehiclePrimarilyKeptAtAHAddress.value
                className: !vehicleData.isVehiclePrimarilyKeptAtAHAddress.value ? classNames(styles.garagingAddressSpanOpacity) : classNames(styles.garagingAddressSpan)
            },
            garageAddrContainerMobile: {
                className: !vehicleData.isVehiclePrimarilyKeptAtAHAddress.value ? classNames(styles.garageAddressMobileSpan, styles.mobileAddressVisible, styles.mobileGarageOpacity) : classNames(styles.garageAddressMobileSpan, styles.mobileAddressVisible, styles.mobileGarage)
            },
            addressFirst: {
                content: `${primaryAddress.addressLine1} ${addressLine2}`
            },
            addressSecond: {
                content: `${primaryAddress.city}, ${primaryAddress.state}, ${primaryAddress.postalCode}`
            },
            garageAddress: {
                showErrors: error,
                visible: !vehicleData.isVehiclePrimarilyKeptAtAHAddress.value,
                triggerStdProcess: this.getTriggerStdProcess(),
                updateStandardizationRan: this.updateStandardizationRan, // so the AddressLookupComponent can turn off the Standardization trigger
                lockAddressState: false,
                fromVehiclePage: true,
                showPOBoxError: this.getShowPOBoxError(),
                showCountyField: this.getShowCounty(),
                showInvalidZip: showInvalidZip
            },
            addButton: {
               // disabled: this.disableAddButton()
            },
            imageContainer: {
                visible: displayImage && stockPhoto !== undefined,
                className: classNames(styles.imageContainer, {
                    digitalFieldComponentAliginLeft: labelPosition === 'left'
                })
            },
            imageComponent: {
                src: stockPhoto
            },
            vehicleVin: {
                valueChanged: true
            }
        };
        const resolvers = {
            resolveValue: this.readValue,
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                ymmSelect: this.ymmSelect,
                vinSelect: this.vinSelect,
                addVehicle: this.addVehicle,
                clearVehicle: this.clearVehicle
            },
            resolveComponentMap: {
                addressLookupComponent: AddressLookupComponent,
                tooltipcomponent: VehicleInfoComponentTooltip
            }
        };
        return (
            <Loader loaded={!showLoader}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={vehicleData}
                    overrideProps={overrideProps}
                    onValueChange={this.handleChange}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </Loader>
        );
    }
}

export default withViewModelService(
    withAuthenticationContext(withValidation(VehicleInfoLookupComponent))
);
