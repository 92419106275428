import React from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import moment from "moment";

export default class ErrorHandlingUtil {
    static getErrorFromWizard(error) {
        if (error === 'Timeout') {
            return false;
        }
        const state = {
            pathname: '/contact-us',
            state: error
        };
        const quoteIDFromWizard = _.get(error, 'gwInfo.params[0].quoteID');
        const quoteIDFromServerCall = _.get(error, 'error.gwInfo.params[0]');
        const quoteID = quoteIDFromWizard || quoteIDFromServerCall;
        state.customContact = false;
        state.quoteStatus = _.get(error, 'gwInfo.params[0].baseData.periodStatus')
            ? _.get(error, 'gwInfo.params[0].baseData.periodStatus') : 'Draft';
        state.quoteID = quoteID;
        state.agentInfo = _.get(error, 'gwInfo.params[0].baseData.agentInfo.agentInfo[0]');
        // Custom contact us page based on error code
        if (error !== undefined && _.isString(error) && (error.includes('503') !== -1
        || error.includes('502') !== -1 || error.includes('006') !== -1 
        || error.includes('InvalidRecaptcha') !== -1)) {
            state.customContact = true;
            return <Redirect to={state} />;
        }
        if (!_.isUndefined(error.baseError) && (error.baseError.toLowerCase().includes('invalid recaptcha') || error.baseError.toLowerCase().includes('gw_rating_error'))) {
            state.customContact = true;
            return <Redirect to={state} />;
          
        }
        if (error && error.docuSignInd) {
            state.docuSignInd = true;
        }
        // Contact us page
        return <Redirect to={state} />;
    }

    static processErrorResponse(props, error, submissionObj, page = null) {

        if (error !== null && error !== undefined && error.status === "Error") {
                error.errorDescription = error.errorCode === '200' ? 'Alfa PreFill Service is down': error.errorDescription
            }

             window.dataLayer.push({
               'event': "Contact Us",
               'serviceEndPoint': _.get(error, "gwInfo.serviceEndPoint") || null,
               'method': _.get(error, "gwInfo.method") || null,
               'params': _.get(error, "gwInfo.params") || null,
               'page': page,
               'isTimeout': _.get(error, "isTimeout") || false,
               'isUnauthenticated': _.get(error, "isUnauthenticated") || false,
               'sysDate': moment(new Date().toLocaleString("en-US", { timeZone: "CST" })).format("lll"),
               'appErrorCode':_.get(error, "errorCode") || _.get(error, "appErrorCode") || null,
               'baseError': _.get(error, "errorDescription") || _.get(error, "baseError") || error || null,
               'errorCode': _.get(error, "appErrorCode") || null,  
             });

        const state = {
            pathname: '/contact-us',
            state: error
        };
        const baseError = !_.isUndefined(error.baseError) ? error.baseError.toLowerCase(): undefined;
        state.customContact = false;
        state.quoteStatus = _.get(submissionObj, 'baseData.periodStatus') ? _.get(submissionObj, 'baseData.periodStatus') : 'Draft';
        state.quoteID = _.get(submissionObj, 'quoteID');
        state.agentInfo = _.get(submissionObj, 'baseData.agentInfo.agentInfo[0]');
        if ((error && error.baseError && (baseError.includes('503') !== -1
        || baseError.includes('502') !== -1 || baseError.includes('006') !== -1))
            || (error && error.baseError && (baseError.includes('uwcustomcontactus')
            || baseError.includes('uwcontactus')))) {
            if (error && error.baseError && baseError.includes('uwcontactus')) {
                state.customContact = false;
            } else {
                state.customContact = true;
            }
        }
        if (error !== null && error !== undefined && typeof error === 'string' && (error.includes('503') !== -1
            || error.includes('502') !== -1 || error.includes('006') !== -1)) {
            state.customContact = true;
        }
        
        // code for Docusign custom contact us page, Vin and Clue
        if (error && !_.isUndefined(error.errorCode) && (error.errorCode.includes('002') || error.errorCode.includes('006'))) {
            state.customContact = true;
        }
        if (error && !_.isUndefined(error.baseError) && baseError.includes('invalid recaptcha')) {
            state.customContact = true;          
        }
        if (error !== null && error !== undefined && typeof error === 'string' && (error.includes('AlfaPriorCarrier') !== -1
        || error.includes('serviceError'))) {
            state.customContact = true;          
        }
        if (error !== null && error !== undefined && error.clickedBack) {
          state.customContact = false;
        }
        if (error && error.docuSignInd) {
            state.docuSignInd = true;
            state.quoteStatus = error.quoteStatus === 'docusign' ? 'Quoted' : state.quoteStatus;
        }
        return props.history.push({
            pathname: '/contact-us',
            state: state
        });
    }
}
