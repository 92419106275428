import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import stereotype from 'stereotype';
import { withRouter } from 'react-router-dom';
import { Tooltip, IconButton, ModalService, Loader } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { DriverLicenseService } from 'gw-capability-driverlicense';
import { EntityUtil } from 'gw-portals-util-js';
import { PrefillLookupService, AddCoverablesService } from 'gw-capability-policycommon-alfa';
import ErrorHandlingUtil from '../../../../../applications/quote-and-buy/src/pages/Utils/ErrorHandlingUtil';

import { readViewModelValue } from 'gw-jutro-adapters-react';
import { LoadSaveService } from 'gw-capability-quoteandbind';

// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import { getConfigValue } from '@jutro/config';
import DriverHeaderComponent from '../../components/DriverHeaderComponent/DriverHeaderComponent';

import styles from './DriversPage.module.scss';
import metadata from './DriversPage.metadata.json5';
import messages from './DriversPage.messages';
import { object } from 'prop-types';
import moment from 'moment'

function DriverTooltip(messageType) {
    const translator = useContext(TranslatorContext);
    const messagePath = 'quoteandbind.pa.directives.templates.pa-driver-details.';
    const message = `${messagePath}${messageType.id}`;

    return (
        <Tooltip
            animation="fade"
            content={translator(_.find(messages, { id: message }))}
            delay={[
                0,
                40
            ]}
            duration={[
                300,
                300
            ]}
            flipBehavior={[
                'right',
                'bottom',
                'top',
                'left',
                'right'
            ]}
            followCursor={false}
            hideOnClick={false}
            id="tooltip"
            showOnInit={false}
            sticky
            trigger="mouseenter"
        >
            <IconButton icon="fa-info-circle" aria-haspopup="true" className={styles.gwToolTip} aria-hidden="true" aria-label="Information" />
        </Tooltip>
    );
}
function DriversPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useContext(TranslatorContext);
    const [isPageInitialized, setPageInitialized] = useState(false);
    const [canShowSixMonthsCovQuestion, setCanShowSixMonthsCovQuestion] = useState(undefined);
    const [currentCarrier, setCurrentCarrier] = useState(false);
    const [hadContServInPast6MOs, updateHadContServInPast6MOs] = useState(undefined);
    const [hasprefillDrivers, updateHasprefillDrivers] = useState(false);
    const [openIndex, updateOpenIndex] = useState(undefined);
    const [selectedDriverCount, updateSelectedDriverCount] = useState(0);
    let [affinityType, updateAffinityType] = useState(undefined);
    const [isSpouseNotAdded, setIsSpouseNotAdded] = useState(false);
    const [incorrectMarital, updateIncorrectMarital] = useState(undefined);
    const [showError, updateShowError] = useState(false);
    const [nextErrorMessage, updateNextErrorMessage] = useState(false);
    const [nextPNIErrorMessage, updatePNINextErrorMessage] = useState(false);
    const [isLoading, updateIsLoading] = useState(false);
    const [dirtyFlag, updateDirtyFlag] = useState(false);
    const [dummyFlag, setDummyFlag] = useState(1);
    const [openAcc, setOpenAcc] = useState(true);
    const [openDriverValid, setOpenDriverValid] = useState(showError);
    const [triggerDateError, setTriggerDateError] = useState(false);
    const [newDriverDateInvalid, setNewDriverDateInvalid] = useState(false);
    const [isSpouseNotAddedForSeperated, setIsSpouseNotAddedForSeperated] = useState(false);
    const [validSpouseCount, updateValidSpouseCount] = useState(false);

    const date = _.get(submissionVM, "baseData.sysDate.value");
    const accountHolderDOB = submissionVM.baseData.accountHolder.dateOfBirth.value;
    _.set(submissionVM, "baseData.sysDate", moment(date).format("YYYY-MM-DD"));

    let currentCarrierExt = undefined;
    const page = "Drivers Page";
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation
    } = useValidation('DriverPage');

    const writeValue = useCallback(
        (value, path) => {
            const vmPath = path.replace(/\.value$/, '');
            const inputCtrlType = _.get(submissionVM, `${vmPath}.aspects.inputCtrlType`);
            let effectiveValue;
            switch (inputCtrlType) {
                case 'boolean':
                case 'number':
                    effectiveValue = stereotype(value);
                    break;
                default:
                    effectiveValue = value;
                    break;
            }
            _.set(submissionVM, path, effectiveValue);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const writeAffinityType = useCallback((value) => {
        updateAffinityType(value);
    }, []);

    const callCaptchaErrorHandler = useCallback((error) => {
        if (!_.isUndefined(error.baseError) && error.baseError.includes('Invalid Recaptcha')) {
            throw error;
        } else {
            ErrorHandlingUtil.processErrorResponse(props, error, submissionVM.value, page);
        }
    }, [submissionVM]
    );

    const editDriver = useCallback(
        (path, driverIndex, isOpen) => {
            const driverPath = path.replace(/\.children\.(\d+)/, '.children[$1].value');
            const driver = _.get(submissionVM, driverPath);
            if (!isOpen) {
                updateOpenIndex(driverIndex);
            } else {
                updateOpenIndex(undefined);
                _.set(driver, 'isOpen', false);
            }
            updateWizardData(submissionVM);
        }, [submissionVM, updateWizardData]
    );

    const getSelectedDriverCount = () => {
        let selectedCount = 0;
        const driversPath = 'lobData.personalAuto.coverables.drivers';
        const drivers = _.get(submissionVM, `${driversPath}.value`);
        _.forEach(drivers, (v) => {
            if (v.isSelectedDriver) {
                selectedCount += 1;
            }
        });
        return selectedCount;
    };

    const getPrefillDriverCount = () => {
        const driversPath = 'lobData.personalAuto.coverables.drivers';
        const drivers = _.get(submissionVM, `${driversPath}.value`);
        const prefillDriver = _.find(drivers, (v) => {
            return (!v.isSelectedDriver && v.isPrefillDriver);
        });
        return (prefillDriver !== null && prefillDriver !== undefined);
    };

    const getAge = (dateOfBirth) => {
        if (!_.isEmpty(dateOfBirth) && dateOfBirth.year !== null) {
            const today = new Date();
            // eslint-disable-next-line max-len
            const birthDate = new Date(String(dateOfBirth.year), String(dateOfBirth.month), String(dateOfBirth.day));
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age -= 1;
            }
            return age;
        }
        return 0;
    };

    const createDriverVM = useCallback((isPolicyHolder) => {

        const accountHolder = submissionVM.baseData.accountHolder.value;
        const address = _.get(submissionVM, 'baseData.accountHolder.primaryAddress');
        const driverState = address.state.value.code;


        const driverObj = {
            accidents: '0',
            violations: '0',
            isPolicyHolder: isPolicyHolder,
            isSelectedDriver: false,
            isPrefillDriver: false,
            person: {},
            isOpen: false,
            licenseState: driverState,
            baseState: driverState
        };
        const { _dtoName, _xCenter } = submissionVM.lobData.personalAuto.coverables.drivers;
        const driverVM = viewModelService.create(driverObj, _xCenter, _dtoName);
        if (isPolicyHolder) {
            _.set(driverVM, 'firstName', accountHolder.firstName);
            _.set(driverVM, 'lastName', accountHolder.lastName);
            _.set(driverVM, 'dateOfBirth', accountHolder.dateOfBirth);
            _.set(driverVM, 'driverAge', getAge(accountHolder.dateOfBirth));
            _.set(driverVM, 'isCurrentCarrierExpired', true);
            _.set(driverVM, 'person', accountHolder);
        }
        const accidentVal = driverVM.numberOfMajorViolations.aspects.availableValues[0];
        const violationVal = driverVM.numberOfMinorViolations.aspects.availableValues[0];
        _.set(driverVM, 'numberOfMajorViolations', accidentVal);
        _.set(driverVM, 'numberOfMinorViolations', violationVal);
        _.set(driverVM, 'tktsOrViolationsInLastThreeYears', false);


        submissionVM.lobData.personalAuto.coverables.drivers.pushElement(driverVM);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, viewModelService]);

    const checkSpouseDriver = () => {
        const driversPath = 'lobData.personalAuto.coverables.drivers';
        const drivers = _.get(submissionVM, `${driversPath}.value`);
        const spouseDriver = _.find(drivers, (d) => {
            return d.relationshipToPNI !== undefined && d.relationshipToPNI !== null && d.relationshipToPNI === 'SP';
        });

        if (spouseDriver !== null && spouseDriver !== undefined) {
            setIsSpouseNotAdded(false);
            return true;
        }
        return false;
    };

    const checkDriverSelected = (prefillDriver) => {
        let result = false;
        const driversPath = 'lobData.personalAuto.coverables.drivers.children';
        const drivers = _.get(submissionVM, driversPath, {});
        if (drivers.length === 0) {
            return result;
        }
        let isCurrentCarrierExpired;
        _.forEach(drivers, (driver) => {
            if ((!_.isEmpty(driver.person.firstName.value) && !_.isEmpty(prefillDriver.firstName)
                && driver.person.firstName.value.toLowerCase() === prefillDriver.firstName.toLowerCase())
                && (!_.isEmpty(driver.person.lastName.value) && !_.isEmpty(prefillDriver.lastName)
                    && driver.person.lastName.value.toLowerCase() === prefillDriver.lastName.toLowerCase())) {
                if (currentCarrier) {
                    isCurrentCarrierExpired = false;
                } else {
                    isCurrentCarrierExpired = true;
                }
                _.set(driver, 'isCurrentCarrierExpired', isCurrentCarrierExpired);
                result = true;
            }
        });

        return result;
    };

    const addDrivers = useCallback((prefillDrivers, hasCurrentCarrier, hadServInPast6MOs) => {
        if (prefillDrivers.drivers !== undefined && prefillDrivers.drivers.length === 0) {
            const driversPath = 'lobData.personalAuto.coverables.drivers.children';
            const drivers = _.get(submissionVM, driversPath, {});

            const pni = _.find(drivers, (d) => {
                return d.isPolicyHolder !== undefined && d.isPolicyHolder.value;
            });
            const address = _.get(submissionVM, 'baseData.accountHolder.primaryAddress');
            const driverState = address.state.value.code;

            if (pni === null || pni === undefined) {
                const accountHolder = submissionVM.baseData.accountHolder.value;
                const driverObj = {
                    accidents: '0',
                    violations: '0',
                    lastName: accountHolder.firstName,
                    firstName: accountHolder.lastName,
                    isPolicyHolder: true,
                    person: accountHolder,
                    isSelectedDriver: false,
                    isPrefillDriver: false,
                    isOpen: false,
                    isCurrentCarrierExpired: true,
                    licenseState: driverState,
                    baseState: driverState
                };
                const { _dtoName, _xCenter } = submissionVM.lobData.personalAuto.coverables.drivers;
                const pniDriver = viewModelService.create(driverObj, _xCenter, _dtoName);
                const accidentVal = pniDriver.numberOfMajorViolations.aspects.availableValues[0];
                const violationVal = pniDriver.numberOfMinorViolations.aspects.availableValues[0];
                _.set(pniDriver, 'numberOfMajorViolations', accidentVal);
                _.set(pniDriver, 'numberOfMinorViolations', violationVal);

                submissionVM.lobData.personalAuto.coverables.drivers.pushElement(pniDriver);
                updateWizardData(submissionVM);
            } else {

                pni.isCurrentCarrierExpired.value = true;
            }
        }
        if (prefillDrivers.drivers !== undefined && prefillDrivers.drivers.length > 0) {
            const address = _.get(submissionVM, 'baseData.accountHolder.primaryAddress');
            const driverState = address.state.value.code;
            _.forEach(prefillDrivers.drivers, (driver) => {
                if (!checkDriverSelected(driver)) {
                    const driverObj = {
                        accidents: '0',
                        violations: '0',
                        tempID: EntityUtil.nextId(),
                        isPrefillDriver: true,
                        isSelectedDriver: false,
                        isPolicyHolder: driver.isPolicyHolder,
                        licenseNumberExists_alfa: driver.licenseExist_alfa,
                        licenseNumber: driver.licenseNumber,
                        isOpen: driver.isPolicyHolder,
                        licenseState: !_.isEmpty(driver.licenseState) ? driver.licenseState : driverState,
                        baseState: driverState
                    };
                    if (driver.isPolicyHolder) {
                        driver.dateOfBirth = submissionVM.baseData.accountHolder.dateOfBirth.value;
                    }
                    // eslint-disable-next-line max-len
                    const { _xCenter, _dtoName } = submissionVM.lobData.personalAuto.coverables.drivers;
                    const prefillDriver = viewModelService.create(driverObj, _xCenter, _dtoName);
                    prefillDriver.person = {};
                    _.set(prefillDriver, 'person.firstName', driver.firstName);
                    _.set(prefillDriver, 'person.lastName', driver.lastName);

                    if (driver.dateOfBirth !== undefined) {
                        _.set(prefillDriver, 'person.dateOfBirth', driver.dateOfBirth);
                        _.set(prefillDriver, 'dateOfBirth', driver.dateOfBirth);
                    }

                    const accidentVal = prefillDriver.numberOfMajorViolations.aspects.availableValues[0];
                    const violationVal = prefillDriver.numberOfMinorViolations.aspects.availableValues[0];
                    _.set(prefillDriver, 'numberOfMajorViolations', accidentVal);
                    _.set(prefillDriver, 'numberOfMinorViolations', violationVal);

                    if (prefillDriver.licenseNumberExists_alfa.value !== undefined
                        && prefillDriver.licenseNumberExists_alfa.value) {
                        _.set(prefillDriver, 'validUSLicense', true);
                    } else {
                        _.set(prefillDriver, 'validUSLicense', false);
                    }
                    if (driver.gender === 'M') {
                        _.set(prefillDriver, 'gender', true);
                    } else {
                        _.set(prefillDriver, 'gender', false);
                    }

                    if (driver.isPolicyHolder) {
                        _.set(prefillDriver, 'isOpen', true);
                        if (hasCurrentCarrier) {
                            setCurrentCarrier(true);
                            updateHadContServInPast6MOs(hadServInPast6MOs);
                            _.set(prefillDriver, 'isCurrentCarrierExpired', false);
                            _.set(prefillDriver, 'haveCurrentInsurance', true);
                            _.set(prefillDriver, 'hadContServInPast6MOs_alfa', hadServInPast6MOs);

                            if (currentCarrierExt !== undefined && currentCarrierExt !== null) {
                                let carrierIndex = _.findIndex(prefillDriver.currentInsurancyCmpy.aspects.availableValues, (item) => item.desc.toUpperCase() === currentCarrierExt.toUpperCase());
                                if (carrierIndex === -1) {
                                    _.set(prefillDriver, 'currentInsurancyCmpy', 'other');
                                    _.set(prefillDriver, 'otherInsuranceCompanyName', currentCarrierExt);
                                } else {
                                    _.set(prefillDriver, 'currentInsurancyCmpy', currentCarrierExt);
                                }
                            }
                        } else {
                            _.set(prefillDriver, 'isCurrentCarrierExpired', true);
                            _.set(prefillDriver, 'haveCurrentInsurance', false);
                        }
                    } else {
                        _.set(prefillDriver, 'isOpen', false);
                        _.set(prefillDriver, 'dontWishToAddDriver', false);
                    }

                    if (canShowSixMonthsCovQuestion !== undefined && !canShowSixMonthsCovQuestion) {
                        _.set(prefillDriver, 'isCurrentCarrierExpired', true);
                        _.set(prefillDriver, 'haveCurrentInsurance', false);
                    }
                    _.set(prefillDriver, 'driverAge', getAge(driver.dateOfBirth));
                    _.set(prefillDriver, 'tktsOrViolationsInLastThreeYears', false);
                    submissionVM.lobData.personalAuto.coverables.drivers.pushElement(prefillDriver);
                    updateWizardData(submissionVM);
                } else {
                    const driverPath = 'lobData.personalAuto.coverables.drivers.children';
                    const allDrivers = _.get(submissionVM, driverPath, {});
                    _.forEach(allDrivers, (d, index) => {
                        const licenseState = !_.isEmpty(d.licenseState) ? d.licenseState : driverState;
                        _.set(submissionVM, `lobData.personalAuto.coverables.drivers.children.${index}.licenseState`, licenseState);
                        if ((!_.isEmpty(d.person.firstName.value) && !_.isEmpty(driver.firstName)
                            && d.person.firstName.value.toLowerCase() === driver.firstName.toLowerCase())
                            && (!_.isEmpty(d.person.lastName.value) && !_.isEmpty(driver.lastName)
                                && d.person.lastName.value.toLowerCase() === driver.lastName.toLowerCase())) {
                            if (hasCurrentCarrier) {
                                setCurrentCarrier(true);
                                updateHadContServInPast6MOs(hadServInPast6MOs);
                                _.set(submissionVM, `lobData.personalAuto.coverables.drivers.children.${index}.isCurrentCarrierExpired`, false);
                            } else {
                                _.set(submissionVM, `lobData.personalAuto.coverables.drivers.children.${index}.isCurrentCarrierExpired`, true);
                            }
                        }
                    });
                    updateWizardData(submissionVM);
                }
            });
            updateHasprefillDrivers(true);
        }
        // eslint-disable-next-line max-len
    }, [currentCarrier, hadContServInPast6MOs, submissionVM, updateWizardData, viewModelService]);

    useEffect(() => {
        // Creating Prefill request object
        const prefillInfoLookupRequestDTO = {
            quoteId: submissionVM.quoteID.value
        };

        let extAffType = {};
        if (submissionVM.lobData.personalAuto.coverables.affinityGroupType.value === 'State') {
            extAffType = { code: "State", name: "State Government" };
        } else if (submissionVM.lobData.personalAuto.coverables.affinityGroupType.value === 'Federal') {
            extAffType = { code: "Federal", name: "Federal Government" };
        } else if (submissionVM.lobData.personalAuto.coverables.affinityGroupType.value === 'Auto Manufacturer') {
            extAffType = { code: "Auto Manufacturer", name: "Auto Manufacturer" };
        } else if (submissionVM.lobData.personalAuto.coverables.affinityGroupType.value === 'None') {
            extAffType = { code: "None", name: "None" };
        }

        if (submissionVM.lobData.personalAuto.coverables.affinityGroupType.value !== undefined) {
            writeAffinityType(submissionVM.lobData.personalAuto.coverables.affinityGroupType.value);
        }
        if (submissionVM.value.lobData.personalAuto.coverables.drivers.length === 0) {
            writeAffinityType(undefined);
        }

        async function discountRibbon() {
            const quoteId = _.get(submissionVM, 'quoteID.value');
            const disco = await LoadSaveService.getQuoteDiscounts(quoteId);
            _.set(submissionVM, 'baseData.discount', disco);
        }
        // Calling prefill service
        updateIsLoading(true);
        const responsePromise = PrefillLookupService.getPrefillData(prefillInfoLookupRequestDTO);
        responsePromise.then((response) => {
            if (response.status === 'Error') {
                ErrorHandlingUtil.processErrorResponse(props, response, submissionVM.value, page);
            }
            if (response.priorPolicies !== undefined && response.priorPolicies.length > 0) {
                window.scrollTo(0, 0);
                let hasCurrentCarrier = false;
                let hadServInPast6MOs = undefined;
                _.forEach(response.priorPolicies, (policy) => {
                    if (policy.policyStatus !== undefined && policy.policyStatus.toUpperCase() === 'IN-EFFECT') {
                        if (policy.carrier.toUpperCase() === 'ALFA') {
                            if (submissionVM.baseData.agentId.value !== undefined) {
                                const kickOutPage = getConfigValue('ALFA_KICKOUT_AGENT_PAGE_URL');
                                location.replace(kickOutPage + '?agentId=' + submissionVM.baseData.agentId.value);
                            } else {
                                const kickOutPage = getConfigValue('ALFA_KICKOUT_PAGE_URL');
                                location.replace(kickOutPage);
                            }
                        }
                        setCurrentCarrier(true);
                        updateHadContServInPast6MOs(policy.hadContServInPast6MOs_alfa);
                        hasCurrentCarrier = true;
                        hadServInPast6MOs = policy.hadContServInPast6MOs_alfa;
                        setCanShowSixMonthsCovQuestion(true);
                        currentCarrierExt = policy.carrier;
                    }
                    if (policy.policyStatus !== undefined && policy.policyStatus.toUpperCase() === 'EXPIRED') {
                        setCanShowSixMonthsCovQuestion(false);
                        setCurrentCarrier(false);
                    }
                    if (typeof response === 'object' && response.drivers.length !== 0) {
                        addDrivers(response, hasCurrentCarrier, hadServInPast6MOs);
                    }
                });
            }
            if (response.priorPolicies !== undefined && response.priorPolicies.length === 0) {
                window.scrollTo(0, 0);
                setCanShowSixMonthsCovQuestion(false);
                setCurrentCarrier(false);
            }

            if (!getPrefillDriverCount() && getSelectedDriverCount() === 0) {
                createDriverVM(true);
            }
            createDriverVM(false);
            updateIsLoading(false);
            const hasPrefill = getPrefillDriverCount();
            updateHasprefillDrivers(hasPrefill);
            const selectedCount = getSelectedDriverCount();
            updateSelectedDriverCount(selectedCount);
            discountRibbon();
            setPageInitialized(true);
        }, (error) => {
            console.log(error);
            callCaptchaErrorHandler(error);
            if (!getPrefillDriverCount() && getSelectedDriverCount() === 0) {
                createDriverVM(true);
            }
            createDriverVM(false);
            updateIsLoading(false);
            const hasPrefill = getPrefillDriverCount();
            updateHasprefillDrivers(hasPrefill);
            const selectedCount = getSelectedDriverCount();
            updateSelectedDriverCount(selectedCount);
            setPageInitialized(true);

        });

        // No array dependencies needed in this hook.
        // The logic of initializing drivers data needs to be executed only once
        // when landing into drivers page.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateVehicleCost = () => {
        const vehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value');
        const vehicleArr = [];
        vehicles.map((vehicleObj, index) => {
            if (_.isUndefined(vehicleObj.costNew)) {
                const updatevehicle = Object.assign(vehicleObj, { costNew: {} });
                console.log(updatevehicle);
            }
            vehicleArr.push(vehicleObj);
            return vehicleArr;
        });
        _.set(submissionVM, 'lobData.personalAuto.coverables.vehicles.value', vehicleArr);
    };
    const onNext = useCallback(async () => {
        _.unset(submissionVM.value, 'bindData');
        const driversPath = 'lobData.personalAuto.coverables.drivers';
        dataLayer.push({ 'event': 'gtm.click', 'gtm.elementId': 'qb_next' });
        updateIncorrectMarital(undefined);
        setIsSpouseNotAdded(false);
        updateNextErrorMessage(false);
        updatePNINextErrorMessage(false);

        const drivers = _.get(submissionVM, driversPath);
        const isPNIAdded = _.findIndex(drivers.children, (d) => {
          return d.isPolicyHolder.value && d.isSelectedDriver.value && _.isEqual(d.dateOfBirth.value,accountHolderDOB);
        });
        let inValid = false;
        let spousesCount = 0;
        drivers.forEach((d) => {
            if (!_.isUndefined(d.isSelectedDriver)) {
                if (d.value.isOpen) {
                    if (!d.aspects.subtreeValid && !d.isSelectedDriver.value) {
                        inValid = true;
                    }
                }
                if (d.relationshipToPNI.value && d.relationshipToPNI.value.code === 'SP') {
                    spousesCount += 1;
                }
            }
        });
        if (spousesCount > 1) {
            updateValidSpouseCount(true);
            return false;
        }
        if (inValid) {
            updateShowError(true);
        }
        if (isPNIAdded === -1) {
            updatePNINextErrorMessage(true);
            return false;
        }
        if (selectedDriverCount === 0) {
            updateNextErrorMessage(true);
            return false;
        }
        if (inValid) {
            return false;
        }
        if (affinityType === undefined) {
            updateShowError(true);
            return false;
        }

        const pniDriver = _.find(drivers.children, (d) => {
            return d.isPolicyHolder.value !== undefined && d.isPolicyHolder.value;
        });
        if (pniDriver !== null && pniDriver !== undefined) {
            const pniMaritalStatus = pniDriver.maritalStatus.value;
            const spouseDriver = checkSpouseDriver();
            if (pniMaritalStatus !== undefined && pniMaritalStatus !== null && pniMaritalStatus.code === 'M') {
                if (!spouseDriver) {
                    setIsSpouseNotAdded(true);
                    return false;
                }
            }
            if (pniMaritalStatus !== undefined && pniMaritalStatus !== null && pniMaritalStatus.code === 'P') {
                if (!spouseDriver) {
                    setIsSpouseNotAddedForSeperated(true);
                    return false;
                }
            }
            const spouse = _.find(drivers.value, (d) => {
                return !_.isEmpty(d.relationshipToPNI) && d.relationshipToPNI === 'SP';
            });
            if (!_.isEmpty(spouse) && !_.isEmpty(pniMaritalStatus)
                && (pniMaritalStatus.code === 'S' || pniMaritalStatus.code === 'W' || pniMaritalStatus.code === 'D')) {
                const maritalStatus = translator({ id: pniMaritalStatus.name, defaultMessage: pniMaritalStatus.code });
                updateIncorrectMarital(maritalStatus);
                return false;
            }
        }
        const driverLen = drivers.value.length - 1;
        const driversData = drivers.value;
        for (let j = driverLen; j >= 0; j--) {
            const driver = driversData[j];
            if (!driver.isSelectedDriver) {
                const newDriverList = _.remove(driversData,
                    (driverFromList) => _.isEqual(driverFromList, driver));
                _.set(submissionVM, newDriverList, driversData);
            }
        }
        _.set(submissionVM, 'lobData.personalAuto.coverables.affinityGroupType', affinityType);
        updateShowError(false);
        updateNextErrorMessage(false);
        // This  is a temperory fix for the Null Pointer Exception we get for updateCoverables api
        // call.Need a fix from the Backend team
        updateVehicleCost();

        const quoteId = submissionVM.quoteID.value;
        const updatedCoverables = await AddCoverablesService.updateCoverables(submissionVM.value);
        _.set(submissionVM, 'baseData.value.agentId', updatedCoverables.baseData.agentId);
        _.set(submissionVM, 'baseData.value.agentAssignment', updatedCoverables.baseData.agentAssignment);

        await LoadSaveService.getClueReport(quoteId, false).then((response) => {
            if (!_.isUndefined(response.clueReports) && response.clueReports.length > 0) {
                _.set(submissionVM, 'lobData.personalAuto.coverables.driverHistory.value', response.clueReports);
            }
            if(_.get(response,'errorCode')){
                callCaptchaErrorHandler(response);
            }
        })
            , (error) => {
                callCaptchaErrorHandler(error);
            };
        const discounts = await LoadSaveService.getQuoteDiscounts(quoteId);
        _.set(submissionVM, 'baseData.discount', discounts);
        return submissionVM;
    }, [submissionVM, selectedDriverCount, affinityType]);

    const closeAllTiles = useCallback(() => {
        const driversPath = 'lobData.personalAuto.coverables.drivers.children';
        const drivers = _.get(submissionVM, driversPath);
        _.forEach(drivers, (vehicle, index) => {
            _.set(submissionVM, `lobData.personalAuto.coverables.drivers.children.${index}.value.isOpen`, false);
        });
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const addDriver = useCallback(() => {
        closeAllTiles();
        updateWizardData(submissionVM);
        const currentSelectedDriver = getSelectedDriverCount();
        updateSelectedDriverCount(currentSelectedDriver);
        const currentPrefillCount = getPrefillDriverCount();
        updateHasprefillDrivers(currentPrefillCount);
        updateOpenIndex(undefined);
        if (!_.isUndefined(incorrectMarital) || isSpouseNotAdded) {
            const driversPath = 'lobData.personalAuto.coverables.drivers';
            const drivers = _.get(submissionVM, driversPath);
            const pniDriver = _.find(drivers.children, (d) => {
                return d.isPolicyHolder.value !== undefined && d.isPolicyHolder.value;
            });
            if (pniDriver !== null && pniDriver !== undefined) {
                const pniMaritalStatus = pniDriver.maritalStatus.value;
                if (isSpouseNotAdded) {
                    checkSpouseDriver();
                }
                if ((pniMaritalStatus !== undefined && pniMaritalStatus !== null && pniMaritalStatus.code === 'M') && !isSpouseNotAdded) {
                    updateIncorrectMarital(undefined);
                }
            }
        }
        setTimeout(() => {
            setDummyFlag(dummyFlag ? undefined : 1);
        }, 2);
    }, [closeAllTiles, updateWizardData, submissionVM, getSelectedDriverCount, getPrefillDriverCount, dummyFlag]);

    const removeDriver = useCallback(
        (currentPath, driverData, toClearOnly) => {
            const driverListPath = 'lobData.personalAuto.coverables.drivers.value';
            let driverPath = '';
            if (currentPath !== '') {
                driverPath = currentPath.replace(/\.children\.(\d+)/, '.children[$1].value');
            }
            const driver = currentPath !== '' ? _.get(submissionVM, driverPath) : driverData;
            const driverList = _.get(submissionVM, driverListPath);
            // Creating remove driver request object
            if (toClearOnly) {
                closeAllTiles();
                setOpenAcc(undefined);
                updateDirtyFlag(false);
                updateShowError(false);
                setTriggerDateError(false);
                setNewDriverDateInvalid(false);
                updateWizardData(submissionVM);
            } else {
                const removeDriverRequestDTO = {
                    quoteNumber: _.get(submissionVM, 'quoteID.value', {}),
                    driver: driver
                };
                updateIsLoading(true);
                // Calling Remove Driver service
                const responsePromise = AddCoverablesService.removeDriver(removeDriverRequestDTO);
                responsePromise.then((response) => {
                    _.set(driver, 'isSelectedDriver', false);
                    _.set(driver, 'isOpen', false);
                    _.set(driver, 'relationshipToPNI', undefined);
                    if (!driver.isPrefillDriver) {
                        const newDriverList = _.remove(driverList,
                            (driverFromList) => _.isEqual(driverFromList, driver));
                        _.set(submissionVM, newDriverList, driverListPath);
                    }
                    updateWizardData(submissionVM);
                    const currentSelectedDriver = getSelectedDriverCount();
                    updateSelectedDriverCount(currentSelectedDriver);
                    const hasPrefill = getPrefillDriverCount();
                    updateHasprefillDrivers(hasPrefill);
                    updateIsLoading(false);
                }, (error) => {
                    callCaptchaErrorHandler(error);
                    updateIsLoading(false);
                });
            }
        },
        [closeAllTiles, getPrefillDriverCount, getSelectedDriverCount, submissionVM, updateWizardData]
    );

    const dateOfBirthInValid = useCallback((invalid) => {
        setNewDriverDateInvalid(invalid);
    }, [setNewDriverDateInvalid]);

    const newDriverEmptyCheck = (driverVM) => {
        if ((!driverVM.dateOfBirth.value && driverVM.isPrefillDriver.value) || driverVM.isPolicyHolder.value || (!driverVM.dateOfBirth.value
            && !driverVM.person.firstName.value && !driverVM.person.lastName.value && !driverVM.gender.value)) {
            if (!driverVM.person.suffix.value && !driverVM.maritalStatus.value
                && !driverVM.relationshipToPNI.value && !driverVM.tktsOrViolationsInLastThreeYears.value) {
                if (!driverVM.dontWishToAddDriver.value || driverVM.isPolicyHolder.value) {
                    return true;
                }
            }
        }
        return false;
    };

    const checkDriverValid = useCallback((driverVM) => {
        if (!driverVM.aspects.subtreeValid) {
            if (!driverVM.isSelectedDriver.value) {
                const valid = newDriverEmptyCheck(driverVM) && !newDriverDateInvalid;
                if (!valid) {
                    setTriggerDateError(true);
                } else {
                    setTriggerDateError(false);
                }
                return valid;
            }
            return false;
        }
        return true;
    }, [setTriggerDateError, newDriverDateInvalid]);

    const checkAccDriverValid = useCallback((driverVM) => {
        if (!driverVM.aspects.subtreeValid) {
            return false;
        }
        return true;
    }, []);

    const updateOpenAccValidInd = (path) => {
        const driverPath = path.replace(/\.children\.(\d+)/, '.children[$1]');
        const driver = _.get(submissionVM, driverPath);
        if (driver.value.isOpen) {
            if (!checkAccDriverValid(driver)) {
                setOpenDriverValid(false);
                updateDirtyFlag(true);
            } else {
                setOpenDriverValid(true);
                updateDirtyFlag(false);
            }
        }
    };

    const closeOtherTiles = useCallback((path, driverIndex) => {
        const driversPath = 'lobData.personalAuto.coverables.drivers.children';
        const drivers = _.get(submissionVM, driversPath);
        const driverPath = path.replace(/\.children\.(\d+)/, '.children[$1]');
        const driverCheck = submissionVM.lobData.personalAuto.coverables.drivers.children[driverIndex];
        const driver = _.get(submissionVM, driverPath);
        let isOtherDriverAccValid = true;
        _.forEach(drivers, (d) => {
            if (d.value.isOpen && !checkDriverValid(d)) {
                isOtherDriverAccValid = false;
            }
        });
        if (driver.isOpen !== undefined && driver.isOpen) {
            if (!checkDriverValid(driverCheck)) {
                _.set(driver, 'isOpen', true);
                updateShowError(true);
                updateDirtyFlag(true);
            } else {
                _.set(driver, 'isOpen', false);
                updateShowError(false);
                updateWizardData(submissionVM);
            }
        } else if (!dirtyFlag && isOtherDriverAccValid) {
            _.forEach(drivers, (v, index) => {
                if (index !== driverIndex) {
                    _.set(submissionVM, `lobData.personalAuto.coverables.drivers.children.${index}.value.isOpen`, false);
                } else {
                    _.set(submissionVM, `lobData.personalAuto.coverables.drivers.children.${index}.value.isOpen`, true);
                }
            });
            updateDirtyFlag(false);
            setOpenAcc(!openAcc);
            updateShowError(false);
            updateWizardData(submissionVM);
        }
    }, [submissionVM, dirtyFlag, checkDriverValid, updateWizardData, openAcc]);

    const renderDriverAccordionHeader = useCallback(
        (driverName, pathOfDriverToRender, driverIndex, isPrefill, isSelected, isPolicyHolder, isVehicleOpen) => {
            return () => (
                <div className={styles.accordionHeaderContainer}>
                    <DriverHeaderComponent
                        driverName={driverName}
                        path={pathOfDriverToRender}
                        driverIndex={driverIndex}
                        isAccordionOpen={isVehicleOpen}
                        isPrefill={isPrefill}
                        isSelected={isSelected}
                        isPolicyHolder={isPolicyHolder}
                        onRemoveDriver={removeDriver}
                        onEditDriver={editDriver}
                        closeOtherTiles={closeOtherTiles}
                    />
                </div>
            );
        }, [removeDriver, editDriver, closeOtherTiles]
    );

    const getCollapseClass = (index) => {
        const driver = submissionVM.lobData.personalAuto.coverables.drivers.children[index];
        if (driver.value.isOpen) {
            return 'blockCollapse';
        }
        if (!driver.value.isOpen) {
            return 'allowCollapse';
        }
        return 'allowCollapse';
    };

    const checkOpenDriver = useCallback(() => {
        const driver = _.find(submissionVM.lobData.personalAuto.coverables.drivers.children, (v) => {
            return v.value.isOpen === true;
        });
        if (driver !== null && driver !== undefined) {
            return true;
        }
        return false;
    }, []);

    const onFileUpload = useCallback(
        async (file, index) => {
            const driverPath = `lobData.personalAuto.coverables.drivers.children[${index}]`;
            const driverLicenseData = await DriverLicenseService.uploadBarcode(file);
            const driverVM = _.get(submissionVM, driverPath);

            Object.entries(driverLicenseData).forEach(([key, value]) => {
                if (_.has(driverVM, key)) {
                    _.set(driverVM, `${key}.value`, value);
                }
                if (_.has(driverVM.person, key)) {
                    _.set(driverVM.person, `${key}.value`, value);
                }
            });
            writeValue(driverVM.value, `${driverPath}.value`);
        },
        [submissionVM, writeValue]
    );

    const isPNIHasCommodity = () => {
        let isPNIAddedCommodity;
        const drivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value', []);
        for (let count = 0; count < drivers.length; count++) {
            const driver = drivers[count];
            if (driver && driver.isSelectedDriver) { //&& driver.isPolicyHolder) {
                if (driver.occupation === 'Farmer' && driver.commodities && driver.commodities.length > 0) {
                    isPNIAddedCommodity = true;
                    break;
                } else {
                    isPNIAddedCommodity = false;
                }
            } else {
                isPNIAddedCommodity = false;
            }
        }
        return isPNIAddedCommodity;
    }


    const generateOverrides = useCallback(() => {
        const driverPath = 'lobData.personalAuto.coverables.drivers';

        const drivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value', []);
        const driversVM = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers', []);
        const overrideProps = {};
        let commodityFlag = true;
        const address = _.get(submissionVM, 'baseData.accountHolder.primaryAddress');

        if (address.state.value.code !== 'AL') {
            commodityFlag = false;
        }
        let isPolicyHolderFarmer = false;

        let isPNIAddedCommodity = false;

        drivers.forEach((driver, index) => {
            const isAccountHolder = driver.isPolicyHolder;
            const overridePath = `${driverPath}.children[${index}]`;
            let driverTitle = translator(messages.paDriverTitle);
            const driverState = address.state.value.code;

            if (driver.isPolicyHolder) {
                if (!_.isUndefined(driver.occupation)) {
                    isPolicyHolderFarmer = (driver.occupation === 'Farmer');
                    if (driver.occupation === 'Farmer' && driver.commodities && !driver.commodities.every(_.isNull)) {
                        isPNIAddedCommodity = true;
                    }
                }
            }
            if (drivers.length > 1 && index !== 0) {
                driverTitle = `${translator(messages.paDriverTitle)} ${index + 1}`;
            }
            let driverName = <div className={styles.driverOnlyNameContainer}><span className={styles.driverName}>{driver.person.firstName} {driver.person.lastName}</span></div>
            if (driver.driverAge > 0){
                driverName = <div className={styles.driverNameContainer}><span className={styles.driverName}>{driver.person.firstName} {driver.person.lastName}</span> <span className={styles.driverAge}>{driver.driverAge} years old</span></div>
            }
            if (driver.dontWishToAddDriver){
                driverName = <div className={styles.driverNameContainer}><span className={styles.driverName}>{driver.person.firstName} {driver.person.lastName}</span> <span className={styles.driverAge}>{driver.driverAge} years old  | Excluded</span></div>
            } 
            let addDriverName = 'Add Driver';
            if (driver.firstName !== undefined) {
                addDriverName = driverName;
            }

            overrideProps[`driverComponent${index}`] = {
                addDriver: addDriver,
                currentCarrier: currentCarrier,
                createDriverVM: createDriverVM,
                quoteId: _.get(submissionVM, 'quoteID.value', {}),
                showError: showError,
                commodityFlag: commodityFlag,
                driverState: driverState,
                removeDriver: removeDriver,
                updateOpenAccValidInd: updateOpenAccValidInd,
                isPNIHasCommodity: isPNIAddedCommodity,
                driverAtleastOneCommodity: isPNIHasCommodity(),
                dateOfBirthInValid: dateOfBirthInValid,
                drivers: drivers,
                isPolicyHolderFarmer: isPolicyHolderFarmer,
                canShowSixMonthsCovQuestion: canShowSixMonthsCovQuestion,
                triggerDateError: !driver.isPrefillDriver && !driver.isSelectedDriver && !driver.isPolicyHolder ? triggerDateError : false
            };
            overrideProps[`driverSelectedAccordionDiv${index}`] = {
                className: !driver.isOpen && checkOpenDriver() ? 'hideAccordion' : 'driverAccordionContainer',
            };
            overrideProps[`driverPrefillAccordionDiv${index}`] = {
                className: !driver.isOpen && checkOpenDriver() ? 'hideAccordion' : 'driverAccordionContainer',
            };
            overrideProps[`driverNewAccordionDiv${index}`] = {
                className: !driver.isOpen && checkOpenDriver() ? 'hideAccordion' : 'driverAccordionContainer',
            };
            overrideProps[`driverPrefillAccordionCard${index}`] = {
                header: renderDriverAccordionHeader(
                    driverName,
                    `${overridePath}.value`,
                    index,
                    true,
                    false,
                    false,
                    driver.isOpen
                ),
                visible: driver.isPrefillDriver !== undefined && driver.isPrefillDriver && !driver.isSelectedDriver,
                collapseClassName: getCollapseClass(index)
            };
            overrideProps[`newDriverAccordionCard${index}`] = {
                header: renderDriverAccordionHeader(
                    addDriverName,
                    `${overridePath}.value`,
                    index,
                    false,
                    false,
                    false,
                    driver.isOpen
                ),
                visible: !driver.isPrefillDriver && !driver.isSelectedDriver,
                collapseClassName: getCollapseClass(index)
            };

            overrideProps[`driverSelectAccordionCard${index}`] = {
                header: renderDriverAccordionHeader(
                    driverName,
                    `${overridePath}.value`,
                    index,
                    false,
                    true,
                    driver.isPolicyHolder,
                    driver.isOpen,
                    editDriver,
                    removeDriver
                ),
                visible: driver.isSelectedDriver,
                collapseClassName: getCollapseClass(index)
            };
            overrideProps[`scanDriverLicenseBarcodeContainer${index}`] = {
                visible: !isAccountHolder && (breakpoint === 'phone' || breakpoint === 'tablet')
            };
            overrideProps[`paDriverTitle${index}`] = {
                value: driverTitle
            };
            overrideProps[`scanDriverLicenseBarcode${index}`] = {
                onValueChange: undefined,
                onUpload: (file) => onFileUpload(file, index),
                maxFileSize: config.maxFileUploadSize
            };
            overrideProps[`paDeleteDriver${index}`] = {
                visible: !isAccountHolder
            };
        });

        return overrideProps;
    }, [submissionVM, translator, addDriver, currentCarrier, createDriverVM, showError, removeDriver, updateOpenAccValidInd, checkOpenDriver, renderDriverAccordionHeader, getCollapseClass, editDriver, breakpoint, onFileUpload]);

    const getDefaultOpenTile = () => {
        const driversPath = 'lobData.personalAuto.coverables.drivers';
        const drivers = _.get(submissionVM, `${driversPath}.value`);
        const phDriver = _.find(drivers, (v) => {
            return (!v.isSelectedDriver && v.isPolicyHolder);
        });
        if (phDriver !== undefined) {
            if (phDriver.isPrefillDriver) {
                return 'driverPrefillAccordionCard0';
            }
            return 'newDriverAccordionCard0';
        }
        return '';
    };

    const getMaritalStatusError = (status) => {
        const maritalError = `You have indicated a driver is your spouse, but your marital status is ${status}. Please update your marital status.`;
        return maritalError;
    };

    const getAvailableAffinityValues = () => {
        if (submissionVM.baseData.policyAddress.value.state === 'AL') {
            return [
                {
                    code: "Federal",
                    name: {
                        id: "Federal",
                        defaultMessage: "Federal Government"
                    }
                },
                {
                    code: "State",
                    name: {
                        id: "State",
                        defaultMessage: "State Government"
                    }
                },
                {
                    code: "Auto Manufacturer",
                    name: {
                        id: "Auto Manufacturer",
                        defaultMessage: "Auto Manufacturer"
                    }
                },
                {
                    code: "None",
                    name: {
                        id: "None",
                        defaultMessage: "None"
                    }
                }
            ]
        } else {
            return [
                {
                    code: "Federal",
                    name: {
                        id: "Federal",
                        defaultMessage: "Federal Government"
                    }
                },
                {
                    code: "State",
                    name: {
                        id: "State",
                        defaultMessage: "State Government"
                    }
                },
                {
                    code: "None",
                    name: {
                        id: "None",
                        defaultMessage: "None"
                    }
                }
            ]
        }

    }

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true
        },
        driverAccordionSet: {
            defaultOpenedId: getDefaultOpenTile()
        },
        addDriver: {
            // disabled: !isComponentValid
        },
        prefillContainer: {
            visible: hasprefillDrivers
        },
        selectedContainer: {
            visible: (selectedDriverCount > 0)
        },
        paDriverNextError: {
            visible: nextErrorMessage
        },
        paPNIDriverNextError: {
            visible: nextPNIErrorMessage
        },
        paDriverSpouseError: {
            visible: isSpouseNotAdded
        },
        paDriverPageSeperateError: {
            visible: isSpouseNotAddedForSeperated
        },
        paDriverPageMultipleSpouseError: {
            visible: validSpouseCount
        },
        paDriverMaritalStatus: {
            visible: incorrectMarital !== undefined,
            content: getMaritalStatusError(incorrectMarital)
        },
        affinityToggle: {
            value: affinityType,
            onValueChange: writeAffinityType,
            showErrors: showError,
            required: showError,
            availableValues: getAvailableAffinityValues()
        },
        ...generateOverrides()
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [overrideProps, submissionVM]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onAddDriverClick: addDriver,
            onRemoveDriver: removeDriver,
            onValidate: onValidate,
        },
        resolveComponentMap: {
            tooltipcomponent: DriverTooltip
        }
    };

    if (!isPageInitialized) {
        return null;
    }

    const removeNotSelectedDrivers = () => {
        const driversPath = 'lobData.personalAuto.coverables.drivers';
        const drivers = _.get(submissionVM, driversPath);
        const driverLen = drivers.value.length - 1;
        const driversData = drivers.value;
        for (let i = driverLen; i >= 0; i--) {
            const driver = driversData[i];
            if (!driver.isSelectedDriver) {
                const newDriverList = _.remove(driversData,
                    (driverFromList) => _.isEqual(driverFromList, driver));
                _.set(submissionVM, newDriverList, driversData);
            }
        }
        updateVehicleCost();
        updateWizardData(submissionVM);
    };
    const onPrevious = () => {
        const driversPath = 'lobData.personalAuto.coverables.drivers';
        const drivers = _.get(submissionVM, driversPath);
        let inValid = false;
        drivers.forEach((d) => {
            if (!_.isUndefined(d.isSelectedDriver)) {
                if (d.isSelectedDriver.value || d.value.isOpen) {
                    if (!d.aspects.subtreeValid) {
                        inValid = true;
                    }
                }
            }
        });
        if (!inValid && selectedDriverCount > 0) {
            removeNotSelectedDrivers();
        }
    };

    const skipDrivers = () => {
        const drivingHistory = submissionVM.value.lobData.personalAuto.coverables.driverHistory;
        if ((submissionVM.value.baseData.periodStatus === 'Quoted') || drivingHistory) {
            removeNotSelectedDrivers();
        }
        return (submissionVM.value.baseData.periodStatus === 'Quoted') || drivingHistory;
    };

    return isLoading ? (
        <Loader loaded={!isLoading} />
    ) : (
        <WizardPage onNext={onNext} onPrevious={onPrevious} skipWhen={skipDrivers} showCancel={false} previousLabel="Back" nextLabel="Next : Quote">
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

DriversPage.propTypes = wizardProps;
export default withRouter(DriversPage);